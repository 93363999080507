import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useContext } from "react"
import { Row, Col, Card, CardBody, Input, Badge, Modal } from "reactstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { DeleteCustomerGroup, SearchCustomerGroup } from "../../services/Api/Module/CustomerGroup";
import Spinners from "components/Common/Spinner";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainerAPI from '../../components/Common/TableContainerAPI';
import { withTranslation } from "react-i18next";

import moment from "moment";
import Pagination from "components/Common/Pagination";
import InputWait from "components/Common/InputWait";
import ModalContext from "contexts/ModalContext";
// import { checkScope } from "helpers/utility_helper";

const RowStatus = ({ status }) => {
    switch (status) {
        case "inactive":
            return <Badge className="bg-warning"> Inactive </Badge>;
    
        case "deleted":
            return <Badge className="bg-danger"> Deleted </Badge>;
    
        case "active":
            return <Badge className="bg-success"> Active </Badge>;
    
        default:
            return <Badge className="bg-success"> {status} </Badge>;
    }
};
const DEF_LIMIT = 10;
const DEF_OFFSET = 0;

const CustomerGroupList = (props) => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();

    // Main DATA
    const limit = DEF_LIMIT;
    const offset = searchParams.get("offset") ?? DEF_OFFSET;
    const sortField = searchParams.get("sortField") ?? '';
    const sortOrder = searchParams.get("sortOrder") ?? 'asc';
    const [rowCount, setRowCount] = useState();
    const [segmentationList, setCustomerGroupList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    
    const [customerGroupName, setCustomerGroupName] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    const tempParams = {
        offset: offset,
        sortField: sortField,
        sortOrder: sortOrder
    }

    const onSort = (event) => {
        console.log("🚀 ~ onSort ~ event:", event)
        const { sortField, sortOrder } = event
        setSearchParams({ ...tempParams, sortField, sortOrder: sortOrder });
    };

    const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if ( findOldValue && findOldValue.length) {
            updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
            updateState = tmpOldValues
            updateState.push(selectedId)
        }

        if (mode === "all") {
            if (tmpOldValues.length >= option.length) {
            updateState = []
            } else {
            updateState = option.map(item => item.id)
            }
    
        }
        console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
        setSelectedIds(updateState)
    };




    const fetchSearchCustomerGroup = async (payload) => {
        try {
            const response = await SearchCustomerGroup({ params: payload })
            const resData = response.data
          // const resData = await getCustomerProfile()
            setIsLoading(false)
            if (resData.resultCode === 20000) {
                console.log('resData.resultData >>', resData.resultData)
                setCustomerGroupList(resData.resultData)
                setRowCount(resData.rowCount)
            }
        }
        catch (err) {
            console.log('err ', err)
            setIsLoading(false)
        }
    }

    const fetchDeleteCustomerGroup = async (payload) => {
        try {
            const response = await DeleteCustomerGroup({ data: payload })
            const resData = response.data
            // const resData = await getCustomerProfile()
            if (resData.resultCode === 20203) {
                mAlert.info({
                    title: "Alert",
                    subTitle: "Delete Data Success",
                    content: "",
                    onClose: () => {
                        fetchAPI()
                        setSearchParams({ ...tempParams, offset: 0});
                        setSelectedIds([])
                    },
                })
            }
        }
        catch (err) {
            console.log('err ', err)
            mAlert.info({
                title: "Alert",
                subTitle: "Something went wrong",
                content: "",
                onClose: () => {
                //  alert("onClose")
                },
            })
        }
    }

    const fetchAPI = () => {
        const payload = {
            customerGroupName: customerGroupName,
            sortBy: `${sortField}|${sortOrder}`,
            offset: offset,
            limit: limit
        }
        setIsLoading(true)
        fetchSearchCustomerGroup(payload)
    }

    useEffect(() => {
        fetchAPI()
        setSearchParams({ ...tempParams, offset: 0});
    }, [customerGroupName]);


    useEffect(() => {
        fetchAPI()
    }, [sortField, sortOrder, offset]);


    const onDeleteRow = (ids = [], name = '') => {
        mAlert.info({
            type: "confrim",
            title: "Alert Confrim",
            subTitle: `Are you sure Delete ${name} ?`,
            mode: "red",
            content: "",
            onClose: () => {},
            onYes: () => { 
                const payload = { customerGroupIds: ids};
                fetchDeleteCustomerGroup(payload)
            },
            onNo: () => { console.log("Cancel") },
        })
    }

    const onSelectAll = (_selectedIds = [], _tableList = []) => {
        const oldSelectedIds = _.cloneDeep(_selectedIds)
        const allIds = _tableList.map(item => item.id)

        if (_.isEmpty(_.difference(allIds, oldSelectedIds))) {
            // console.log('onSelectAll AAA')
            // console.log('onSelectAll AAA oldSelectedIds', oldSelectedIds)
            // console.log('onSelectAll AAA allIds', allIds)
            const newSelectIds = oldSelectedIds.filter(item => !allIds.includes(item))
            setSelectedIds(newSelectIds)

        } else {
            // console.log('onSelectAll BBB')
            // console.log('onSelectAll BBB oldSelectedIds', oldSelectedIds)
            // console.log('onSelectAll BBB allIds', allIds)
            const newSelectIds = [...new Set([ ...allIds, ...oldSelectedIds ])]
            setSelectedIds(newSelectIds)
        }
    }


    const columns = useMemo(
        () => {
            const formatDate = (txtDate = '') => {
                if (txtDate) {
                    return moment(txtDate).add('h', 7).format('DD/MM/YYYY HH:mm')
                }
                return "-"
            }
            return [
                {
                    id: "colcheckbox",
                    header: () => {
                        return (
                            <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => onSelectAll(selectedIds, segmentationList)}>
                                <Input type={`checkbox`} checked={selectedIds.length === rowCount && rowCount > 0} style={{ margin: "0px", marginBottom: "4px"}} />
                            </div>
                    )},
                    accessorKey: 'id',
                    enableColumnFilter: false,
                    enableSorting: false,
                    cell: (cellProps) => {
                        const item = { ...cellProps.row.original }
                        return (
                            <>
                                <div style={{ display: "flex", justifyContent: "center", cursor: "pointer"  }} onClick={(e) => onSelectionChange(item, _.cloneDeep(selectedIds), _.cloneDeep(segmentationList))}>
                                    <Input 
                                        type={`checkbox`} 
                                        checked={selectedIds.includes(item.id)} 
                                        
                                    />
                                </div>
                            </>
                        )
                    }
                },
                // {
                //     header: 'id',
                //     enableColumnFilter: false,
                //     enableSorting: true,
                //     cell: (cellProps) => {
                //         return (
                //             <>
                //                 <div style={{ display: "flex", justifyContent: "center" }}>
                //                     { cellProps.row.original.id }
                //                 </div>
                //             </>
                //         )
                //     }
                // },
                {
                    header: 'Customer Group Name',
                    accessorKey: 'name',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Created On',
                    accessorKey: 'createdOn',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps, value) => <div>{formatDate(cellProps.row.original.createdOn)}</div>
                },
                {
                    header: 'Updated On',
                    accessorKey: 'updatedOn',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps) => <div>{formatDate(cellProps.row.original.updatedOn)}</div>
                },
                // {
                //     header: 'User Status',
                //     accessorKey: 'user_status',
                //     enableColumnFilter: false,
                //     enableSorting: true,
                //     cell: (cellProps) => <RowStatus status={cellProps.row.original.user_status} />
                // },
                {
                    header: 'Action',
                    accessorKey: 'user_id',
                    enableColumnFilter: false,
                    enableSorting: false,
                    cell: (cellProps) => {
                        let _id = cellProps.row.original.id;
                        const { segmentationCount, name } = cellProps.row.original
                        return (
                            <div className="d-flex gap-1">
                                <Link className={`btn btn-warning`} to={`/customer-group-edit/${_id}`}><i className={`bx bx-edit-alt`}></i></Link>
                                {
                                    segmentationCount == "0" && (
                                        <div className={`btn btn-danger`} onClick={() => onDeleteRow([_id], name)} ><i className={`bx bx-basket`}></i></div>
                                    )
                                }
                                
                            </div>
                        )
                    }
                },
            ]
        },
        [selectedIds, segmentationList]
    );


    //meta title
    document.title = "CustomerGroup List";

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="CustomerGroup" breadcrumbItem="CustomerGroup" />
                    {
                        isLoading && false ? <Spinners setLoading={(v) => setIsLoading(v)}/>
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center mb-3">
                                                <h5 className="mb-0 card-title">CustomerGroup Lists</h5>
                                            </div>
                                            <div className="d-flex ">
                                                <div className="d-flex align-items-center flex-grow-1">
                                                    <InputWait
                                                        value={customerGroupName ?? ""}
                                                        onChange={value => setCustomerGroupName(String(value))}
                                                        className="form-control search-box me-2 mb-2 d-inline-block"
                                                        placeholder={"Customer Group Name"}
                                                    />
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <Link to="/customer-group-add" className="btn btn-primary"><i className="bx bx-plus align-middle"></i> Add CustomerGroup</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex " style={{ minHeight: 36.5 }}>
                                                <div className="d-flex align-items-center flex-grow-1"></div>
                                                {
                                                    selectedIds.length > 0 && (
                                                        <div className="flex-shrink-0 d-flex gap-2">
                                                            <a className="btn btn-info" onClick={() => setSelectedIds([])}> Clear Selected All</a>
                                                            <a className="btn btn-danger" onClick={() => onDeleteRow(_.cloneDeep(selectedIds))}> Delele Selected</a>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <TableContainerAPI
                                                SearchPlaceholder="Search"
                                                columns={columns}
                                                data={segmentationList || []}
                                                onSort={onSort}
                                                sortField={sortField}
                                                sortOrder={sortOrder}

                                                isAddButton={true}
                                                addRowLink={`/useradd`}
                                                isExportButton={true}
                                                exportLink={`/userexport`}
                                                isGlobalFilter={true}
                                                tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                            />

                                            <Pagination
                                                limit={limit}
                                                offset={offset}
                                                rowCount={rowCount}
                                                onBack={(limit, offset) => {
                                                    setSearchParams({ ...tempParams, offset });
                                                }}
                                                onNext={(limit, offset) => {
                                                    setSearchParams({ ...tempParams, offset });
                                                }}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    {/* {customerGroupName} */}
                

                
            </div>
        </div>
    );
}

CustomerGroupList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(CustomerGroupList)