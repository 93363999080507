import React from "react"
import PropTypes from 'prop-types'
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const navigate = useNavigate()
  const { backBTN = "" } = props
  return (
    <Row>
      <Col xs={12}>
        <div className="page-title-box d-sm-flex align-items-center justify-content-between" style={{ paddingInline: '12px' }}>
          <div className="d-flex gap-2 align-items-center pl-2">
            {backBTN ? ( <a onClick={() => navigate(backBTN)} className="btn p-0"><i className="bx bx-left-arrow-alt align-middle" style={{fontSize: '24px'}}></i></a> ): <></>}
            <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem><Link to="#">{props.title}</Link></BreadcrumbItem>
              <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
