import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  CardBody,
  Container,
  CardTitle,
  Label,
  Card,
  Input,
  FormFeedback
} from "reactstrap";
import Select from "react-select";

// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { InputformSegmentation, GetSegmentationById, UpdateSegmentation } from "../../services/Api/Module/Segmentation";
import { CreateSegmentation } from "../../services/Api/Module/Segmentation";
import { Link } from "react-router-dom";
import OperatorDropDown from "./OperatorDropDown";
import OperatorInput from "./OperatorInput";
import helper from "services/helper";

import ModalContext from "contexts/ModalContext";

const SCHEDULE_OPTION = [
  { id: 1, name: "Every Day" },
  { id: 2, name: "Every Week" },
  { id: 3, name: "Every Month" },
  { id: 4, name: "Every Year" },
];
const OPERATOR_OPTION_MAIN = [
  { id: "AND", name: "AND" },
  { id: "OR", name: "OR" },
];

const SegmentationTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const qParams = useParams()
  const qSegmentationId = qParams.id

  // form
  const [segmentName, setSegmentName] = useState("");
  const [description, setDescription] = useState("");
  const [scheduleUpdate, setScheduleUpdate] = useState("");
  const [operator, setOperator] = useState("");

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  // INPUT FORM
  const [isSubmit, setIsSubmit] = useState(false)
  const [inputformSegment, setInputformSegment] = useState([])
  const [inputformValue, setInputformValue] = useState({})

  const fetchInputformSegmentation = async (payload) => {
    try {
      const response = await InputformSegmentation({ params: payload })
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20000) {
        console.log('resData.resultData >>', resData.resultData)
        setInputformSegment(resData.resultData)
        const defFilter = fnToInputFilter(resData.resultData)
        setInputformValue(defFilter)
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "Alert",
        subTitle: "Something went wrong",
        content: "",
        onClose: () => {
        //  alert("onClose")
        },
      })
    }
  }

  const fetchCreateSegmentation = async (payload = {}) => {
    try {
      const response = await CreateSegmentation({ data: payload })
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20201) {
        mAlert.info({
          title: "Alert",
          subTitle: "Create Data Success",
          content: "",
          onClose: () => {
            const newId = resData.resultData.id
            // navigate("/segmentation-edit/" + newId)
            navigate("/segmentation")
          },
        })

      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "Alert",
        subTitle: "Something went wrong",
        content: "",
        onClose: () => {
        //  alert("onClose")
        },
      })
    }
  }
  const fetchUpdateSegmentation = async (payload = {}) => {
    try {
      const response = await UpdateSegmentation({ data: payload })
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20202) {
        mAlert.info({
          title: "Alert",
          subTitle: "Update Data Success",
          content: "",
          onClose: () => {
            navigate("/segmentation")
          },
        })
        // const newId = resData.resultData.id
        // navigate("/segmentation-edit/" + newId)
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
        title: "Alert",
        subTitle: "Something went wrong",
        content: "",
        onClose: () => {
        //  alert("onClose")
        },
      })
    }
  }

  const fetchGetSegmentationById = async (payload) => {
    try {
        const response = await GetSegmentationById({ id: payload.segmentationId })
        const resData = response.data
      // const resData = await getCustomerProfile()
        if (resData.resultCode === 20000) {
            console.log('resData.resultData >>', resData.resultData)
            preFilInput(resData.resultData)
            // setSegmentationList(resData.resultData)
            // setRowCount(resData.rowCount)
        }
    }
    catch (err) {
        // console.log('err ', err)
    }
}

  const fnToInputFilter = (resData = []) => {
    const _resData = _.cloneDeep(resData)
    let tmpFilter = _resData.map(item0 => {
      const tmp = {
        configField: item0.id,
        name: item0.tableName + "." + item0.fieldName,
        operator: null,
        value: []
      }
      return tmp
    })
    let ansFilter = {}


    for (let tf of tmpFilter) {
      ansFilter[tf.configField] = tf
    }
    console.log("🚀 ~ fnToInputFilter ~ ansFilter:", ansFilter)

    return ansFilter
  }

  const fnToFilterPayload = (input = {}) => {
    console.log("🚀 ~ fnToFilterPayload ~ input:", input)
    let _filter = []
    for (let ip in input) {
      if (input[ip] && Array.isArray(input[ip].value) && input[ip].value.length) {
        _filter.push(input[ip])
      }
    }

    return _filter
  }

  const checkValidate = (payload = {}, filter = []) => {
    console.log("🚀 ~ checkValidate ~ payload:", payload)
    console.log("🚀 ~ checkValidate ~ filter:", filter)


    let isPass = true

    // เช็คด้านนอก ฟิวปกติ
    const requireField = ['segmentName','description', 'scheduleUpdate', 'operator']
    for (let rk of requireField) {
      console.log("🚀 ~ checkValidate ~ payload["+ rk +"]:", payload[rk])
      if (!payload[rk]) {
        isPass = false;
        break
      }
    }

    // เช็คด้านนอก ฟิวArray
    const requireFieldArray = ['filters']
    for (let rak of requireFieldArray) {
      console.log("🚀 ~ checkValidate Array ~ payload["+ rak +"]:", payload[rak])
      if (Array.isArray(payload[rak]) && payload[rak].length === 0) {
        isPass = false;

        // เขียนเพิ่ม
        if (rak === "filters") {
          mAlert.info({
            title: "Alert",
            subTitle: "Please select at least 1 Condition.",
            content: "",
            onClose: () => {
              //  alert("onClose")
            },
          })
        }
        break
      }
    }

    // เช็คด้านใน Filter ต่อ
    if (isPass) {
      // ค่อยมาเขียนต่อ
    }

    return isPass
  }

  const onSave = () => {
    const payload = {
      id: qSegmentationId ? qSegmentationId : undefined,
      segmentName: segmentName,
      description: description,
      scheduleUpdate: scheduleUpdate,
      operator: operator,
      filters: fnToFilterPayload(inputformValue)
    };
    console.log("🚀 ~ onSave ~ payload:", payload);
    setIsSubmit(true)

    if (checkValidate(payload, payload.filter)) {
      if (pageView === "update") {
        fetchUpdateSegmentation(payload)
      } else {
        fetchCreateSegmentation(payload)
      }
    }
  };


  const preFilInput = (resData = {}) => {
    console.log("🚀 ~ preFilInput ~ resData:", resData)
    const resDataFilters = resData?.filters ?? {}

    setSegmentName(resData?.name)
    setDescription(resData?.description)

    const scheduleUpdateData = resData?.scheduleUpdate ?? null
    const operatorData= resData?.operator ?? null

    setScheduleUpdate(scheduleUpdateData)
    setOperator(operatorData)

    let tmpSave = _.cloneDeep(inputformValue)
    for (let keyId in _.cloneDeep(inputformValue)) {
      const findData = resDataFilters.find(item => item.configFieldId == keyId)
      // console.log("🚀 ~ preFilInput ~ resData:", resData)
      tmpSave[keyId].segmentationId = findData?.segmentationId ?? null
      tmpSave[keyId].id = findData?.id ?? null
      tmpSave[keyId].operator = findData?.operator ?? null
      tmpSave[keyId].value = findData?.value ?? []
    }
    console.log("🚀 ~ preFilInput ~ tmpSave:", tmpSave)
    setInputformValue(tmpSave)
  }

  useEffect(() => {
    fetchInputformSegmentation()
  }, [])

  // กรณี Edit
  useEffect(() => {
    // fetchInputformSegmentation()
    if(inputformSegment.length) {
      if (["update", "view"].includes(pageView)) {
        const payload = { segmentationId : qSegmentationId}
        fetchGetSegmentationById(payload)
      }
    }
  }, [inputformSegment])

  //meta title
  document.title = `Segmentation ${_.capitalize(pageView)}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Create" breadcrumbItem="Segmentation" backBTN={'/segmentation'}/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Segmentation {_.capitalize(pageView)}</CardTitle>
                  <p className="card-title-desc">
                    Fill all information below
                  </p>
                  {/* <p className="card-title-desc">
                    Here are examples of <code>.form-control</code> applied to
                    each textual HTML5 <code>&lt;input&gt;</code>{" "}
                    <code>type</code>.
                  </p> */}

                  <div className="mb-3">
                    <Label>Segmentation Name</Label>
                    <Input
                      type="text"
                      maxLength="255"
                      name="segmentName"
                      value={segmentName}
                      onChange={(e) => setSegmentName(e.target.value)}
                      invalid={isSubmit && !segmentName}
                      disabled={pageView === "view"}
                    />
                    {
                      (isSubmit && !segmentName) && (
                        <FormFeedback type="invalid">This field is required</FormFeedback>
                      )
                    }
                  </div>
                  <div className="mb-3">
                    <Label>Description</Label>
                    <Input
                      type="text"
                      maxLength="255"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      invalid={isSubmit && !description}
                      disabled={pageView === "view"}
                    />
                    {
                      (isSubmit && !description) && (
                        <FormFeedback type="invalid">This field is required</FormFeedback>
                      )
                    }
                  </div>
                  <div className="mb-3">
                    <Label>Schedule Update</Label>
                    <Select
                      value={helper.FNFORM.getObjectValue(SCHEDULE_OPTION, scheduleUpdate)}
                      onChange={(e) => setScheduleUpdate(e.id)}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      options={SCHEDULE_OPTION}
                      className={`select2-selection ${isSubmit && !scheduleUpdate ? "input-err" : ""}` }
                      isDisabled={pageView === "view"}
                    />
                    {
                      (isSubmit && !scheduleUpdate) && (
                        <div type="invalid" className="invalid-feedback d-block">This field is required</div>
                      )
                    }
                  </div>
                  <div className="mb-3">
                    <Label>Operator</Label>
                    <Select
                      value={helper.FNFORM.getObjectValue(OPERATOR_OPTION_MAIN, operator)}
                      onChange={(e) => setOperator(e.id)}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      options={OPERATOR_OPTION_MAIN}
                      className={`select2-selection ${isSubmit && !operator ? "input-err" : ""}` }
                      invalid={true}
                      isDisabled={pageView === "view"}
                    />
                    {
                      (isSubmit && !operator) && (
                        <div type="invalid" className="invalid-feedback d-block">This field is required</div>
                      )
                    }
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {/* {JSON.stringify(inputformValue)} */}
                  <CardTitle className="h4">Conditions</CardTitle>
                  <p className="card-title-desc">
                    (You must select at least 1 conditions.)
                  </p>
                  {
                    inputformSegment
                    .filter(item0 => ["ENUM"].includes(item0.type) || true)
                    .map(item0 => {

                      return (
                        <Row className="mb-3" key={item0.id}>
                          <label
                            htmlFor="example-text-input"
                            className="col-md-3 col-form-label"
                            style={{ textAlign: "right"}}
                          >
                            {item0.labelInput} :
                          </label>
                          <div className="col-md-3">
                            <OperatorDropDown
                              type={item0.type}
                              valueId={inputformValue[item0.id]?.operator}
                              dropDownTableName={item0?.dropDownTableName}
                              other={item0?.other}
                              disabled={pageView === "view"}
                              onChange={(v) => {
                                let tmpSave = _.cloneDeep(inputformValue)
                                tmpSave[item0.id].operator = v
                                
                                // RESET Value = [] ถ้าเลือกใหม่
                                if (!v || v === null) {
                                  tmpSave[item0.id].value = []
                                } 
                                setInputformValue(tmpSave)
                              }}
                            />
                          </div>
                          <>
                            <OperatorInput
                              labelInput={item0.labelInput}
                              type={item0.type}
                              operator={inputformValue[item0.id]?.operator}
                              value={inputformValue[item0.id]?.value}
                              option={item0.optionDropdown}
                              disabled={pageView === "view"}
                              onChange={(v) => {
                                let tmpSave = _.cloneDeep(inputformValue)
                                console.log('OperatorInput onChange v >>', v)
                                tmpSave[item0.id].value = v
                                setInputformValue(tmpSave)
                              }}
                            />
                          </>
                        </Row>
                      )
                    })
                  }
                  <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                    {
                      pageView == "view" 
                      ? <><button className="btn btn-primary w-md" onClick={()=> navigate('/segmentation')}>BACK</button></> 
                      : <>
                        <button className="btn btn-primary w-md" onClick={() => onSave()}>SAVE</button>
                        <button className="btn btn-secondary w-md" onClick={()=> navigate('/segmentation')}>CANCEL</button>
                      </>
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SegmentationTemplate
