import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useContext } from "react"
import { Row, Col, Card, CardBody, Input, Badge } from "reactstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { DeleteBroadcast, SearchBroadcast } from "../../services/Api/Module/Broadcast";
import Spinners from "components/Common/Spinner";
import Select from "react-select";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainerAPI from '../../components/Common/TableContainerAPI';
import { withTranslation } from "react-i18next";

import moment from "moment";
import Pagination from "components/Common/Pagination";
import InputWait from "components/Common/InputWait";
import helper from "services/helper";
import ModalContext from "contexts/ModalContext";
// import { checkScope } from "helpers/utility_helper";


const DEF_LIMIT = 10;
const DEF_OFFSET = 0;

const STATUS_OPTION = [
    { id: null, name: "Select Status" },
    { id: 0, name: "PENDING" },
    { id: 1, name: "SENT" },
];

const BroadcastList = (props) => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();

    // Main DATA
    const limit = DEF_LIMIT;
    const offset = searchParams.get("offset") ?? DEF_OFFSET;
    const sortField = searchParams.get("sortField") ?? '';
    const sortOrder = searchParams.get("sortOrder") ?? 'asc';
    const [rowCount, setRowCount] = useState();
    const [broadcastList, setBroadcastList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    
    const [broadcastName, setBroadcastName] = useState('');
    const [segmentName, setSegmentName] = useState('');
    const [status, setStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const tempParams = {
        offset: offset,
        sortField: sortField,
        sortOrder: sortOrder
    }

    const onSort = (event) => {
        console.log("🚀 ~ onSort ~ event:", event)
        const { sortField, sortOrder } = event
        setSearchParams({ ...tempParams, sortField, sortOrder: sortOrder });
    };

    const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if ( findOldValue && findOldValue.length) {
            updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
            updateState = tmpOldValues
            updateState.push(selectedId)
        }

        if (mode === "all") {
            if (tmpOldValues.length >= option.length) {
            updateState = []
            } else {
            updateState = option.map(item => item.id)
            }
    
        }
        console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
        setSelectedIds(updateState)
    };




    const fetchSearchBroadcast = async (payload) => {
        try {
            const response = await SearchBroadcast({ params: payload })
            const resData = response.data
          // const resData = await getCustomerProfile()
            setIsLoading(false)
            if (resData.resultCode === 20000) {
                console.log('resData.resultData >>', resData.resultData)
                setBroadcastList(resData.resultData)
                setRowCount(resData.rowCount)
            }
        }
        catch (err) {
            console.log('err ', err)
            setIsLoading(false)
        }
    }

    const fetchDeleteBroadcast = async (payload) => {
        try {
            const response = await DeleteBroadcast({ data: payload })
            const resData = response.data
            // const resData = await getCustomerProfile()
            if (resData.resultCode === 20203) {
                mAlert.info({
                    title: "Alert",
                    subTitle: "Delete Data Success",
                    content: "",
                    onClose: () => {
                        fetchAPI()
                        setSearchParams({ ...tempParams, offset: 0});
                    },
                })

            }
        }
        catch (err) {
            console.log('err ', err)
            mAlert.info({
                title: "Alert",
                subTitle: "Something went wrong",
                content: "",
                onClose: () => {
                //  alert("onClose")
                },
            })
        }
    }

    const fetchAPI = () => {
        const payload = {
            broadcastName: broadcastName,
            segmentName: segmentName,
            status: status || status == 0 ? status : undefined,
            sortBy: `${sortField}|${sortOrder}`,
            offset: offset,
            limit: limit
        }
        setIsLoading(true)
        fetchSearchBroadcast(payload)
    }

    useEffect(() => {
        fetchAPI()
        setSearchParams({ ...tempParams, offset: 0});
    }, [broadcastName, segmentName, status]);


    useEffect(() => {
        fetchAPI()
    }, [sortField, sortOrder, offset]);


    const onDeleteRow = (ids = [], name = '') => {
        mAlert.info({
            type: "confrim",
            title: "Alert Confrim",
            subTitle: `Are you sure Delete ${name} ?`,
            mode: "red",
            content: "",
            onClose: () => {},
            onYes: () => { 
                const payload = { broadcastId: ids };
                fetchDeleteBroadcast(payload)
            },
            onNo: () => { console.log("Cancel") },
        })
    }

    const onSelectAll = (_selectedIds = [], _tableList = []) => {
        const oldSelectedIds = _.cloneDeep(_selectedIds)
        const allIds = _tableList.map(item => item.id)

        if (_.isEmpty(_.difference(allIds, oldSelectedIds))) {
            // console.log('onSelectAll AAA')
            // console.log('onSelectAll AAA oldSelectedIds', oldSelectedIds)
            // console.log('onSelectAll AAA allIds', allIds)
            const newSelectIds = oldSelectedIds.filter(item => !allIds.includes(item))
            setSelectedIds(newSelectIds)

        } else {
            // console.log('onSelectAll BBB')
            // console.log('onSelectAll BBB oldSelectedIds', oldSelectedIds)
            // console.log('onSelectAll BBB allIds', allIds)
            const newSelectIds = [...new Set([ ...allIds, ...oldSelectedIds ])]
            setSelectedIds(newSelectIds)
        }
    }


    const columns = useMemo(
        () => {
            const formatDate = (txtDate = '') => {
                if (txtDate) {
                    return moment(txtDate).format('DD/MM/YYYY')
                }
                return "-"
            }
            const formatTime = (txtDate = '') => {
                if (txtDate) {
                    return moment(txtDate, "HH:mm").format('HH:mm')
                }
                return "-"
            }

            const RowStatus = ({ status = "0" }) => {
                const myStyle = { minWidth: '60px', fontSize: '10px', textTransform: 'uppercase'}
                switch (String(status)) {
                    case "0":
                        return <Badge className="bg-warning p-2 px-1" style={myStyle}> Pending </Badge>;
                
                    case "1":
                        return <Badge className="bg-success p-2 px-1" style={myStyle}> Sent </Badge>;
                
                    default:
                        return <Badge className="bg-danger p-2 px-1" style={myStyle}> {status} </Badge>;
                }
            };
            
            return [
                {
                    id: "colcheckbox",
                    header: () => {
                        return (
                            <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => onSelectAll(selectedIds, broadcastList)}>
                                <Input type={`checkbox`} checked={selectedIds.length === rowCount && rowCount > 0} style={{ margin: "0px", marginBottom: "4px"}} />
                            </div>
                    )},
                    accessorKey: 'id',
                    enableColumnFilter: false,
                    enableSorting: false,
                    cell: (cellProps) => {
                        const item = { ...cellProps.row.original }
                        return (
                            <>
                                <div style={{ display: "flex", justifyContent: "center", cursor: "pointer"  }} onClick={(e) => onSelectionChange(item, _.cloneDeep(selectedIds), _.cloneDeep(broadcastList))}>
                                    <Input 
                                        type={`checkbox`} 
                                        checked={selectedIds.includes(item.id)} 
                                        
                                    />
                                </div>
                            </>
                        )
                    }
                },
                {
                    header: 'Broadcast Name',
                    accessorKey: 'name',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Segmentation',
                    accessorKey: 'segmentName',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Status',
                    accessorKey: 'status',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps) => <RowStatus status={cellProps.row.original.status} />
                },

                {
                    header: 'Sent Date',
                    accessorKey: 'sentDate',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps, value) => <div>{formatDate(cellProps.row.original.sentDate)}</div>
                },
                {
                    header: 'Sent Time',
                    accessorKey: 'sentTime',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps) => <div>{formatTime(cellProps.row.original.sentTime)}</div>
                },
                {
                    header: 'Recipients',
                    accessorKey: 'totalRecipients',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps, value) => <div>{cellProps?.row?.original?.totalRecipients ?? '0'}</div>
                },
                {
                    header: 'Impressions',
                    accessorKey: 'totalImpressions',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps, value) => <div>{cellProps?.row?.original?.totalImpressions ?? '0'}</div>
                },
                {
                    header: 'Clicks',
                    accessorKey: 'totalClicks',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps, value) => <div>{cellProps?.row?.original?.totalClicks ?? '0'}</div>
                },
                {
                    header: 'Media Play',
                    accessorKey: 'totalMediaPlay',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps, value) => <div>{cellProps?.row?.original?.totalMediaPlay ?? '0'}</div>
                },
                {
                    header: 'Action',
                    accessorKey: 'user_id',
                    enableColumnFilter: false,
                    enableSorting: false,
                    cell: (cellProps) => {
                        let _id = cellProps.row.original.id;
                        let name = cellProps.row.original.name;
                        let status = cellProps.row.original.status
                        return (
                            <div className="d-flex gap-1">
                                { status == "1"
                                    ? (
                                        <>
                                            <Link className={`btn btn-info`} to={`/broadcast-view/${_id}`}><i className={`bx bx-show-alt`}></i></Link>
                                        </>
                                    )
                                    : (
                                        <>
                                            <Link className={`btn btn-warning`} to={`/broadcast-edit/${_id}`}><i className={`bx bx-edit-alt`}></i></Link> 
                                            <div className={`btn btn-danger`} onClick={() => onDeleteRow([_id], name)} ><i className={`bx bx-basket`}></i></div>
                                        </>
                                    )
                                }
                            </div>
                        )
                    }
                },
            ]
        },
        [selectedIds, broadcastList]
    );


    //meta title
    document.title = "Broadcast List";

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="Broadcast" breadcrumbItem="Broadcast"/>
                    {
                        isLoading && false ? <Spinners setLoading={(v) => setIsLoading(v)}/>
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center mb-3">
                                                <h5 className="mb-0 card-title">Broadcast Lists</h5>
                                            </div>
                                            <div className="d-flex ">
                                                <div className="d-flex align-items-center flex-grow-1 gap-2">
                                                    <Row style={{width: "100%"}}>
                                                    <InputWait
                                                        colSpan={3}
                                                        value={broadcastName ?? ""}
                                                        onChange={value => setBroadcastName(String(value))}
                                                        className="form-control search-box me-2 mb-2 d-inline-block"
                                                        placeholder={"Search Broadcast Name"}
                                                    />
                                                    <InputWait
                                                        colSpan={3}
                                                        value={segmentName ?? ""}
                                                        onChange={value => setSegmentName(String(value))}
                                                        className="form-control search-box me-2 mb-2 d-inline-block"
                                                        placeholder={"Search Segment Name"}
                                                    />
                                                    <Col sm={3}>
                                                        <Select
                                                            className="select2-selection"
                                                            placeholder="Select Status"
                                                            value={helper.FNFORM.getObjectValue(STATUS_OPTION, status)}
                                                            onChange={(e) => setStatus(e.id)}
                                                            getOptionValue={(option) => option.id}
                                                            getOptionLabel={(option) => option.name}
                                                            options={STATUS_OPTION}
                                                        />
                                                    </Col>
                                                    </Row>
                                                    
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <Link to="/broadcast-add" className="btn btn-primary"><i className="bx bx-plus align-middle"></i> Add Broadcast</Link>
                                                </div>
                                            </div>
                                            <div className="d-flex " style={{ minHeight: 36.5 }}>
                                                <div className="d-flex align-items-center flex-grow-1"></div>
                                                {
                                                    selectedIds.length > 0 && (
                                                        <div className="flex-shrink-0 d-flex gap-2">
                                                            <a className="btn btn-info" onClick={() => setSelectedIds([])}> Clear Selected All</a>
                                                            <a className="btn btn-danger" onClick={() => onDeleteRow(_.cloneDeep(selectedIds))}> Delele Selected</a>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <TableContainerAPI
                                                SearchPlaceholder="Search"
                                                columns={columns}
                                                data={broadcastList || []}
                                                onSort={onSort}
                                                sortField={sortField}
                                                sortOrder={sortOrder}

                                                isAddButton={true}
                                                addRowLink={`/useradd`}
                                                isExportButton={true}
                                                exportLink={`/userexport`}
                                                isGlobalFilter={true}
                                                tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                            />

                                            <Pagination
                                                limit={limit}
                                                offset={offset}
                                                rowCount={rowCount}
                                                onBack={(limit, offset) => {
                                                    setSearchParams({ ...tempParams, offset });
                                                }}
                                                onNext={(limit, offset) => {
                                                    setSearchParams({ ...tempParams, offset });
                                                }}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                    {/* {broadcastName} */}
                

                
            </div>
        </div>
    );
}

BroadcastList.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(BroadcastList)