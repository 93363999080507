import React, { useEffect, useState, useMemo } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap"
import helper from "services/helper"
import UploadFileS3 from "./UploadFileS3"

const isDev = helper.ISDEV()

const DEF_ROW = {
    broadcastId: "",
    messageType: 0,
    text: "",

    label: "",
    image:"",
    imageSize: "0x0",
    embedLink: "",

    video:"",
    coverVideo:"",
    flexMessage: "",
}

const MESSAGE_TYPE_REQUIRE = {
    "0": ['text'], // TEXT
    "1": ['label','image','imageSize'], // IMAGE
    "2": ['coverVideo','video'], // VIDEO
    "3": ['label','flexMessage'], // FLEX
}

const MESSAGE_TYPE_FIELD = {
    "0": ['text'], // TEXT
    "1": ['label','image','imageSize','embedLink'], // IMAGE
    "2": ['coverVideo','video'], // VIDEO
    "3": ['label','flexMessage'], // FLEX
}

const ContentModal = ({ 
    isOpen = false,
    mode = "view",
    onClose = () => console.log('On Close!'),
    onNewRow = () => console.log("onNewRow"),
    onUpdateRow = () => console.log("onUpdateRow"),
    currentData = {},
    messageTypeRows = [],
    isView = false
}) => {

    const [ isSubmit, setIsSubmit ] = useState(false)
    const [ rowData, setRowData ] = useState(DEF_ROW)

    const messageTypeRowsStr = _.cloneDeep(messageTypeRows.map(item => String(item)))

    const toggle = () => {
        onClose()
    };

    const handleForm = (value = null, key = '') => {
        console.log("🚀 ~ handleForm ~ value:", value)
        console.log("🚀 ~ handleForm ~ key:", key)
        let tmpRowUpdate = _.cloneDeep(rowData)
        tmpRowUpdate[key] = value

        console.log("🚀 ~ handleForm ~ tmpRowUpdate:", tmpRowUpdate)

        setRowData(tmpRowUpdate)
    }

    const checkValidate = (payload = {}) => {
        console.log("🚀 ~ checkValidate ~ payload:", payload)
    
        let isPass = true
    
        // เช็คด้านนอก ฟิวปกติ
        let requireField = []
        const _messageType = String(payload.messageType)
        if (MESSAGE_TYPE_REQUIRE[_messageType]) {
            requireField = MESSAGE_TYPE_REQUIRE[_messageType]
        }

        // let requireField = ['text']
        for (let rk of requireField) {
            console.log("🚀 ~ checkValidate ~ payload["+ rk +"]:", payload[rk])
            if (!payload[rk]) {
                isPass = false;
                break
            }
        }
    
    
        return isPass
    }


    const fnCleanPayload = (payload = {}) => {
        const _payload = _.cloneDeep(payload)
        let newPayload = {
            id: _payload?.id ? _payload.id : undefined,
            messageType: _payload.messageType
        }
        const _messageType = String(_payload.messageType)

        for (let key in _payload) {

            let requireField = MESSAGE_TYPE_FIELD[_messageType] ? MESSAGE_TYPE_FIELD[_messageType] : []

            if (requireField.includes(key)) {
                newPayload[key] = _payload[key]
            }

        }
        console.log('fnCleanPayload newPayload >>', newPayload)
        return newPayload
    }


    const onSave = () => {
        const payload = _.cloneDeep(rowData)
        setIsSubmit(true)

        if (checkValidate(payload)) {
            if (mode ==="create") {
                const tmpData = fnCleanPayload(payload)
                onNewRow(tmpData)
            } else if (mode ==="update") {
                const tmpData = fnCleanPayload(payload)
                onUpdateRow(tmpData)
            } else {
                onClose()
            }
        }

    }

    useEffect(() => {
        if (isOpen == true) {
            if (mode ==="create") {
                let DEF_NEW_ROW = _.cloneDeep(DEF_ROW)
                
                let noSelectMsgType = ['0','1','2','3'].filter(msgType => messageTypeRowsStr.includes(msgType) === false)
                console.log("🚀 ~ useEffect ~ noSelectMsgType:", noSelectMsgType)
                if (noSelectMsgType.length) {
                    // DEF_NEW_ROW.messageType = noSelectMsgType[0]
                    DEF_NEW_ROW.messageType = '0'
                }
                console.log("🚀 ~ useEffect ~ DEF_NEW_ROW:", DEF_NEW_ROW)
                setRowData(DEF_NEW_ROW)
            } else {
                setRowData(currentData)
            }
        }

        return () => {
            console.log("RESET STATE");
            setRowData(DEF_ROW)
            setIsSubmit(false)
        }
    }, [isOpen])

    return (
    <React.Fragment>
        <Modal isOpen={isOpen} toggle={toggle}  size="xl">
        <ModalHeader toggle={toggle} tag="h4">
            { mode === "create" && 'Add'}
            { mode === "update" && 'Update'}
            { mode === "view" && 'View'}
            <span> Content</span>
        </ModalHeader>
        <ModalBody>
            <Row style={{ pointerEvents: isView ? 'none': 'auto'}}>
                <Col xs={12}>
                    {isDev ? <div className="mb-3">{JSON.stringify(rowData)}</div>: <></>}
                    {isDev ? <div className="mb-3">{JSON.stringify(messageTypeRows)}</div>: <></>}
                    <div className="mb-3">
                        <Label style={{ marginRight: "8px" }}>Message type :</Label>
                        <InputMessageType
                            value={rowData.messageType} 
                            onChange={(v) => {
                                setIsSubmit(false)
                                handleForm(v, 'messageType')
                            }}
                        />
                    </div>
                    {/* TEXT */}
                    {
                        [0,"0"].includes(rowData.messageType) && (
                            <>
                                <div className="mb-3">
                                    <Label>Text</Label>
                                    <Input
                                        className="form-control"
                                        type="textarea"
                                        rows="3"
                                        placeholder="Enter a message"
                                        maxLength="255"
                                        name="text"
                                        value={rowData.text}
                                        onChange={(e) => handleForm(e.target.value, 'text')}
                                        invalid={isSubmit && !rowData.text}
                                    />
                                    {
                                        (isSubmit && !rowData.text) && (
                                            <FormFeedback type="invalid">This field is required</FormFeedback>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                    {/* IMAGE */}
                    {
                        [1,"1"].includes(rowData.messageType) && (
                            <>
                                <div className="mb-3">
                                    <Label>Label</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter a label"
                                        maxLength="255"
                                        name="label"
                                        value={rowData.label}
                                        onChange={(e) => handleForm(e.target.value, 'label')}
                                        invalid={isSubmit && !rowData.label}
                                    />
                                    {
                                        (isSubmit && !rowData.label) && (
                                            <FormFeedback type="invalid">This field is required</FormFeedback>
                                        )
                                    }
                                </div>
                                <div className="mb-3">
                                    <UploadFileS3 
                                        upload="Image"
                                        typeUpload="ImageMap"
                                        label="Image"
                                        description="description"
                                        value={rowData.image}
                                        onChange={([ imageURL, imageSize ]) => {
                                            // handleForm(imageURL, 'image')
                                            let rowDataUpdate = _.cloneDeep(rowData)
                                            rowDataUpdate.image = imageURL
                                            rowDataUpdate.imageSize = imageSize
                                            setRowData(rowDataUpdate)
                                        }}
                                    />
                                    {/* <Label>Image</Label> */}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter a Image URL"
                                        maxLength="255"
                                        name="image"
                                        value={rowData.image}
                                        onChange={(e) => handleForm(e.target.value, 'image')}
                                        invalid={isSubmit && !rowData.image}
                                        disabled={true}
                                    />
                                    {
                                        (isSubmit && !rowData.image) && (
                                            <FormFeedback type="invalid">This field is required</FormFeedback>
                                        )
                                    }
                                </div>

                                <div className="mb-3">
                                    <Label>Embedded links</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter a URL"
                                        maxLength="255"
                                        name="embedLink"
                                        value={rowData.embedLink}
                                        onChange={(e) => handleForm(e.target.value, 'embedLink')}
                                        // invalid={isSubmit && !rowData.embedLink}
                                    />
                                    {/* {
                                        (isSubmit && !rowData.embedLink) && (
                                            <FormFeedback type="invalid">This field is required</FormFeedback>
                                        )
                                    } */}
                                </div>
                            </>
                        )
                    }

                    {/* VIDEO */}
                    {
                        [2,"2"].includes(rowData.messageType) && (
                            <>
                                <div className="mb-3">
                                    {/* <Label>Video</Label> */}
                                    <UploadFileS3
                                        upload="Video"
                                        label="Video" 
                                        description="description"
                                        value={rowData.video}
                                        onChange={([vdoURL]) => {
                                            // console.log('UploadFileS3 e >>',imageURL)
                                            handleForm(vdoURL, 'video')
                                        }}
                                    />
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter a URL"
                                        maxLength="255"
                                        name="video"
                                        value={rowData.video}
                                        onChange={(e) => handleForm(e.target.value, 'video')}
                                        invalid={isSubmit && !rowData.video}
                                        disabled={true}
                                    />
                                    {
                                        (isSubmit && !rowData.video) && (
                                            <FormFeedback type="invalid">This field is required</FormFeedback>
                                        )
                                    }
                                </div>

                                <div className="mb-3">
                                    <UploadFileS3 
                                        upload="Image"
                                        label="Cover Video" 
                                        description="description"
                                        value={rowData.coverVideo}
                                        onChange={([imageURL, imageSize]) => {
                                            // console.log('UploadFileS3 e >>',imageURL)
                                            handleForm(imageURL, 'coverVideo')
                                        }}
                                    />
                                    {/* <Label>Cover Video</Label> */}
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter a URL"
                                        maxLength="255"
                                        name="coverVideo"
                                        value={rowData.coverVideo}
                                        onChange={(e) => handleForm(e.target.value, 'coverVideo')}
                                        invalid={isSubmit && !rowData.coverVideo}
                                        disabled={true}
                                    />
                                    {
                                        (isSubmit && !rowData.coverVideo) && (
                                            <FormFeedback type="invalid">This field is required</FormFeedback>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                    {/* FLEX */}
                    {
                        [3,"3"].includes(rowData.messageType) && (
                            <>
                                <div className="mb-3">
                                    <Label>Label</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter a label"
                                        maxLength="255"
                                        name="label"
                                        value={rowData.label}
                                        onChange={(e) => handleForm(e.target.value, 'label')}
                                        invalid={isSubmit && !rowData.label}
                                    />
                                    {
                                        (isSubmit && !rowData.label) && (
                                            <FormFeedback type="invalid">This field is required</FormFeedback>
                                        )
                                    }
                                </div>
                                <div className="mb-3">
                                    <Label>Flex</Label>
                                    <Input
                                        className="form-control"
                                        type="textarea"
                                        rows="20"
                                        placeholder="Enter a flex"
                                        // maxLength="512"
                                        name="flexMessage"
                                        value={rowData.flexMessage}
                                        onChange={(e) => handleForm(e.target.value, 'flexMessage')}
                                        invalid={isSubmit && !rowData.flexMessage}
                                    />
                                    {
                                        (isSubmit && !rowData.flexMessage) && (
                                            <FormFeedback type="invalid">This field is required</FormFeedback>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-end">
                        {
                            isView 
                            ? <></>
                            : <button className="btn btn-success save-user" onClick={onSave}>Save</button>
                        }
                        
                    </div>
                </Col>
            </Row>
        </ModalBody>
        </Modal>
    </React.Fragment>
    )
}

export default ContentModal


const InputMessageType = ({
    value = 0,
    onChange = () => console.log('onChange!') 
}) => {
    const OPTION = [
        { id: "0", name: "Text" },
        { id: "1", name: "Image" },
        { id: "2", name: "Video" },
        { id: "3", name: "Flex" },
    ]

    return (
        <div className="btn-group btn-group-lg">
            {
                OPTION
                    .map(item => {
                    const isChecked = String(item.id) === String(value)
                    let color = "outline-primary"
                    if (isChecked) {
                        color = "primary"
                    }
                    return (
                        <Button key={item.id} type="button" color={color}onClick={() => onChange(item.id)}>
                            {item.name}
                        </Button>
                    )
                })
            }
        </div>
    )
}