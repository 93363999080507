import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  CardBody,
  Container,
  CardTitle,
  Label,
  Card,
  Input,
  FormFeedback,
  InputGroup
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";


// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { InputformBroadcast, CreateBroadcast, GetBroadcastById, UpdateBroadcast } from "../../services/Api/Module/Broadcast";
import { DDSegmentation } from "../../services/Api/Module/Dropdown";
import { Link } from "react-router-dom";
import OperatorDropDown from "./OperatorDropDown";
import OperatorInput from "./OperatorInput";
import helper from "services/helper";
import moment from "moment";
import ContentList from "./ContentList";
import ContentModal from "./ContentModal";
import ModalContext from "contexts/ModalContext";


const TIME_OPTION = helper.TIME.generateTimes().map(item => ({ id:item, name: item}));
const DEF_CONTENTS = [
  {
    broadcastId: "",
    messageType: "0",
    text: "Hello Text",
  },
  {
    broadcastId: "",
    messageType: "1",
    label: "นี้คือ Image",
    image:
      "https://p16-va.lemon8cdn.com/tos-alisg-i-0000/01ddd41d163a43da98e9066861b7a188~tplv-tej9nj120t-origin.webp",
    embedLink: "https://www.google.co.th",
  },
  {
    broadcastId: "",
    messageType: "2",
    label: "นี้คือ Video",
    video:
      "https://v16-webapp-prime.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/oY6gBRfDQIemR9mERGwqcFURVBpAEEpyFQDcEg/?a=1988&bti=ODszNWYuMDE6&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C&cv=1&br=4138&bt=2069&cs=0&ds=6&ft=-Csk_mXLPD12N88NRT-Uxt~2KY6e3wv25xcAp&mime_type=video_mp4&qs=0&rc=ZDhpPGY1ZGc1OWU1O2lnNUBpM246cng5cnFrcjMzODczNEBhXi00XjViNTExMC8yMmAuYSNpbi81MmQ0aF5gLS1kMS1zcw%3D%3D&btag=e00088000&expire=1721119711&l=20240716024815F5684C6FA251034591DE&ply_type=2&policy=2&signature=b462ec18b72d0e4b77a9df6a4af1fdec&tk=tt_chain_token",
    coverVideo:
      "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/o0AMntn2cqAQBI5E64PBZpFKEiYNViBEDVal1?lk3s=81f88b70&nonce=95050&refresh_token=1b321e49120d753e706720a603d5d7bf&x-expires=1721268000&x-signature=6QkU9RzvhLoW86CwtXuK%2BgWiXTw%3D&shp=81f88b70&shcp=-",
  },
  {
    broadcastId: "",
    messageType: "3",
    label: "นี้คือ Flex Message",
    flexMessage: { flex: "1234" },
  },
]

const BroadcastTemplate = ({
  pageView = "view"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();

  const qParams = useParams()
  const qBroadcastId = qParams.id

  // dropdown API
  const [ddSegmentation, setDDSegmentation] = useState([]);

  // form
  const [broadcastName, setBroadcastName] = useState("");
  const [description, setDescription] = useState("");
  const [segmentationId, setSegmentationId] = useState("");
  const [messageAmount, setMessageAmount] = useState("");
  const [sentDate, setSentDate] = useState("");
  const [sentTime, setSentTime] = useState(null);

  // form List
  const [contents, setContents] = useState([]);

  // INPUT FORM
  const [isSubmit, setIsSubmit] = useState(false)
  const [inputformSegment, setInputformSegment] = useState([])
  const [inputformValue, setInputformValue] = useState({})

  // Popup State
  const [isOpenP0, setIsOpenP0] = useState(false)
  const [modeP0, setModeP0] = useState('view')
  const [indexP0, setIndexP0] = useState(0)

  const fetchDDSegmentation = async () => {
    try {
      const response = await DDSegmentation()
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20000) {
        console.log('resData.resultData >>', resData.resultData)
        setDDSegmentation(resData.resultData)
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
      })
    }
  }

  const fetchCreateBroadcast = async (payload = {}) => {
    try {
      const response = await CreateBroadcast({ data: payload })
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20201) {
        mAlert.info({
          title: "Alert",
          subTitle: "Create Data Success",
          content: "",
          onClose: () => {
            const newId = resData.resultData.id
            // navigate("/broadcast-edit/" + newId)
            navigate("/broadcast")
          },
        })
        
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
      })
    }
  }
  const fetchUpdateBroadcast = async (payload = {}) => {
    try {
      const response = await UpdateBroadcast({ data: payload })
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20202) {
        mAlert.info({
          title: "Alert",
          subTitle: "Update Data Success",
          content: "",
          onClose: () => {
            navigate("/broadcast")
          },
        })
        // const newId = resData.resultData.id
        // navigate("/broadcastation-edit/" + newId)
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
      })
    }
  }

  const fetchGetBroadcastById = async (payload) => {
    try {
        const response = await GetBroadcastById({ id: payload.broadcastationId })
        const resData = response.data
      // const resData = await getCustomerProfile()
        if (resData.resultCode === 20000) {
            console.log('resData.resultData fetchGetBroadcastById >>', resData.resultData)
            preFilInput(resData.resultData)
            // setBroadcastList(resData.resultData)
            // setRowCount(resData.rowCount)
        }
    }
    catch (err) {
        // console.log('err ', err)
    }
}

  const fnToInputFilter = (resData = []) => {
    const _resData = _.cloneDeep(resData)
    let tmpFilter = _resData.map(item0 => {
      const tmp = {
        configField: item0.id,
        name: item0.tableName + "." + item0.fieldName,
        sentDate: null,
        value: []
      }
      return tmp
    })
    let ansFilter = {}


    for (let tf of tmpFilter) {
      ansFilter[tf.configField] = tf
    }
    console.log("🚀 ~ fnToInputFilter ~ ansFilter:", ansFilter)

    return ansFilter
  }

  const checkValidate = (payload = {}) => {
    console.log("🚀 ~ checkValidate ~ payload:", payload)
    


    let isPass = true

    // เช็คด้านนอก ฟิวปกติ
    const requireField = ['broadcastName','description','segmentationId','messageAmount', 'sentTime', 'sentDate']
    for (let rk of requireField) {
      console.log("🚀 ~ checkValidate ~ payload["+ rk +"]:", payload[rk])
      if (!payload[rk]) {
        isPass = false;
        break
      }
    }

    // เช็คด้านนอก ฟิวArray
    const requireFieldArray = ['contents']
    for (let rak of requireFieldArray) {
      console.log("🚀 ~ checkValidate Array ~ payload["+ rak +"]:", payload[rak])
      if (Array.isArray(payload[rak]) && payload[rak].length === 0) {
        isPass = false;

        // เขียนเพิ่ม
        if (rak === "contents") {
          mAlert.info({
            title: "Alert",
            subTitle: "Please select at least 1 Content.",
            content: "",
            onClose: () => {
              //  alert("onClose")
            },
          })
        }
        break
      }
    }

    // เช็คด้านใน Filter ต่อ
    if (isPass) {
      // ค่อยมาเขียนต่อ
    }

    return isPass
  }

  const onSave = () => {
    const payload = {
      id: qBroadcastId ? qBroadcastId : undefined,
      broadcastName: broadcastName,
      description: description,
      segmentationId: segmentationId,
      messageAmount: messageAmount,
      sentTime: sentTime,
      sentDate: sentDate,
      contents: contents
    };
    console.log("🚀 ~ onSave ~ payload:", payload);
    setIsSubmit(true)

    if (checkValidate(payload, payload.filter)) {
      if (pageView === "update") {
        fetchUpdateBroadcast(payload)
      } else {
        fetchCreateBroadcast(payload)
      }
    }
  };


  const preFilInput = (resData = {}) => {
    console.log("🚀 ~ preFilInput ~ resData:", resData)

    setBroadcastName(resData?.name)
    setDescription(resData?.description)

    setSegmentationId(resData?.segmentationId)
    setMessageAmount(resData?.messageAmount)

    const sentTimeData = resData?.sentTime ?? null
    const sentDateData= resData?.sentDate ?? null

    setSentTime(sentTimeData)
    setSentDate(sentDateData)

    const contentsData = resData?.contents ?? []
    setContents(contentsData)
  }

  useEffect(() => {
    fetchDDSegmentation()
  }, [])

  // กรณี Edit
  useEffect(() => {
    
    if (["update","view"].includes(pageView)) {
      const payload = { broadcastationId : qBroadcastId}
      fetchGetBroadcastById(payload)
    }
  }, [])

  //meta title
  document.title = `Broadcast ${_.capitalize(pageView)}`;

  const RowAction = {
    onNew: (row) => {
      let tmpUpdate = _.cloneDeep(contents)
      tmpUpdate.push(row)
      console.log("🚀 ~ onNew tmpUpdate:", tmpUpdate)
      setContents(tmpUpdate)
      setIsOpenP0(false)
    },
    onEdit: (row, index) => {
      let tmpUpdate = _.cloneDeep(contents)
      tmpUpdate[index] = row
      console.log("🚀 ~ onEdit tmpUpdate:", tmpUpdate)
      setContents(tmpUpdate)
      setIsOpenP0(false)
      
    },
    onDelete: (_index) => {
      const _contents = _.cloneDeep(contents)
      const tmpUpdate = _contents.filter((item,i) => _index != i)
      console.log("🚀 ~ onDelete tmpUpdate:", tmpUpdate)
      setContents(tmpUpdate)
      setIsOpenP0(false)
    }
  }

  const RowHandle = {
    onNew: () => {
      setIsOpenP0(true); 
      setModeP0('create')
    },
    onEdit: (selectIndex) => {
      console.log("🚀 ~ RowHandle onEdit:", selectIndex)
      setIsOpenP0(true); 
      setModeP0('update')
      setIndexP0(selectIndex)
    },
    onDelete: (selectIndex) => {
      console.log("🚀 ~ RowHandle onDelete:", selectIndex)
      setIndexP0(selectIndex)

      // ย้ายตรงนี้ด้วยถ้าเปลี่ยน
      RowAction.onDelete(selectIndex)
    }
  }
  
  let TIME_OPTION_FILTER = TIME_OPTION
  const dateNow = moment().format('YYYY-MM-DD')
  console.log("🚀 ~ dateNow:", dateNow)
  console.log("🚀 ~ sentDate:", sentDate)
  if (sentDate == dateNow) {
    const timenow = moment().add(5,'minute').format('HH:mm') // บวกไป 5 นาที กัน user ลีลา
    console.log("🚀 ~ timenow:", timenow)
    TIME_OPTION_FILTER = TIME_OPTION_FILTER.filter(item => item.name >= timenow)
  }
  return (
    <React.Fragment>
      <ContentModal
        isOpen={isOpenP0}
        mode={modeP0}
        onClose={() => setIsOpenP0(false)}
        onNewRow={(row) => RowAction.onNew(row)}
        onUpdateRow={(row) => RowAction.onEdit(row, indexP0)}
        currentData={contents.find((item, index) => indexP0 === index) || {}}
        messageTypeRows={contents.map(item => item.messageType)}
        isView={pageView == "view"}
      />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Create" breadcrumbItem="Broadcast" backBTN={'/broadcast'}/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Broadcast {_.capitalize(pageView)}</CardTitle>
                  <p className="card-title-desc">
                    Fill all information below
                  </p>
                  {/* <p className="card-title-desc">
                    Here are examples of <code>.form-control</code> applied to
                    each textual HTML5 <code>&lt;input&gt;</code>{" "}
                    <code>type</code>.
                  </p> */}
                  <div className="mb-3">
                    <Label>Broadcast Name</Label>
                    <Input
                      type="text"
                      maxLength="255"
                      name="broadcastName"
                      value={broadcastName}
                      onChange={(e) => setBroadcastName(e.target.value)}
                      invalid={isSubmit && !broadcastName}
                      disabled={pageView === "view"}
                    />
                    {
                      (isSubmit && !broadcastName) && (
                        <FormFeedback type="invalid">This field is required</FormFeedback>
                      )
                    }
                  </div>
                  
                  <div className="mb-3">
                    <Label>Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      className="form-control"
                      rows="3"
                      maxLength="255"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      invalid={isSubmit && !description}
                      disabled={pageView === "view"}
                    />
                    {
                      (isSubmit && !description) && (
                        <FormFeedback type="invalid">This field is required</FormFeedback>
                      )
                    }
                  </div>

                  <div className="mb-3">
                        <Label>Segmentation</Label>
                        <Select
                          value={helper.FNFORM.getObjectValue(ddSegmentation, segmentationId)}
                          placeholder="Select Segmentation"
                          onChange={(e) => setSegmentationId(e.id)}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          options={ddSegmentation}
                          className={`select2-selection ${isSubmit && !segmentationId ? "input-err" : ""}` }
                          invalid={true}
                          isDisabled={pageView === "view"}
                        />
                        {
                          (isSubmit && !segmentationId) && (
                            <div type="invalid" className="invalid-feedback d-block">This field is required</div>
                          )
                        }
                      </div> 

                  <Row>
                    <Col sm={4}>
                      <div className="mb-3">
                        <Label>Number of Messages</Label>
                        <Input
                          type="text"
                          maxLength="255"
                          name="messageAmount"
                          value={messageAmount}
                          onInput={(e)=> helper.FNFORM.handleNumberOnly(e)}
                          onChange={(e) => setMessageAmount(e.target.value)}
                          invalid={isSubmit && !messageAmount}
                          disabled={pageView === "view"}
                        />
                        {
                          (isSubmit && !messageAmount) && (
                            <FormFeedback type="invalid">This field is required</FormFeedback>
                          )
                        }
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-3">
                        <Label>Release Date</Label>
                        {
                          (pageView == 'create' || sentDate) && (
                            <InputGroup>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder={ sentDate || "Release Date"}
                              value={sentDate}
  
                              onChange={(e) => {
                                const value = e[0] ? moment(e[0]).format('YYYY-MM-DD') : null
                                setSentDate(value)
                              }}
                              options={{
                                altInput: true,
                                altFormat: "Y-m-d",
                                dateFormat: "Y-m-d",
                                minDate: moment().toDate()
                              }}
                              disabled={pageView === "view"}
                            />
                          </InputGroup>
                          )
                        }
                        {
                          (isSubmit && !sentDate) && (
                            <div type="invalid" className="invalid-feedback d-block">This field is required</div>
                          )
                        }
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mb-3">
                        <Label>Release Time</Label>
                        <Select
                          value={helper.FNFORM.getObjectValue(TIME_OPTION, sentTime)}
                          onChange={(e) => setSentTime(e.id)}
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          options={TIME_OPTION_FILTER}
                          className={`select2-selection ${isSubmit && !sentTime ? "input-err" : ""}` }
                          isDisabled={pageView === "view"}
                        />
                        {
                          (isSubmit && !sentTime) && (
                            <div type="invalid" className="invalid-feedback d-block">This field is required</div>
                          )
                        }
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {/* {JSON.stringify(inputformValue)} */}
                  <CardTitle className="h4">Content</CardTitle>
                  <p className="card-title-desc">
                    (You must select at least 1 content max 4 content.)
                  </p>
                  <ContentList
                    tableData={contents}
                    onOpenNew={() => RowHandle.onNew()}
                    onOpenEdit={(index) => RowHandle.onEdit(index)}
                    onOpenDelete={(index) => RowHandle.onDelete(index)}
                    isView={pageView == "view"}
                  />

                  <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                    {
                      pageView == "view" 
                      ? <><button className="btn btn-primary w-md" onClick={()=> navigate('/broadcast')}>BACK</button></> 
                      : <>
                        <button className="btn btn-primary w-md" onClick={() => onSave()}>SAVE</button>                      
                        <button className="btn btn-secondary w-md" onClick={()=> navigate('/broadcast')}>CANCEL</button>
                      </>
                    }
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BroadcastTemplate
