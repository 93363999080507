import { get, post, put } from "../Config";

export function SearchCustomerGroup({ params }) {
  return get({ url: `/api/g99/v1/customerGroup`, params });
}
export function GetCustomerGroupById({ id }) {
  return get({ url: `/api/g99/v1/customerGroup/${id}` });
}
export function CreateCustomerGroup({ params, data }) {
  return post({ url: `/api/g99/v1/customerGroup/create`, params, data });
}
export function UpdateCustomerGroup({ params, data }) {
  return post({ url: `/api/g99/v1/customerGroup/update`, params, data });
}
export function DeleteCustomerGroup({ params, data }) {
  return post({ url: `/api/g99/v1/customerGroup/delete`, params, data });
}
export function InputformCustomerGroup({ params }) {
  return get({ url: `/api/g99/v1/inputform/customerGroup`, params });
}
