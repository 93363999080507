import React, { useEffect, useContext, useState } from "react";
import Select from "react-select";


const OPERATOR_CONFIG = {
  TEXT: [
    { id: "OR", name: "OR" },
    { id: "NOT_OR", name: "NOT OR" },
  ],
  ENUM: [
    { id: "OR", name: "OR" },
    { id: "NOT_OR", name: "NOT OR" },
  ],
  DATE: [
    { id: "BETWEEN", name: "BETWEEN" },
    { id: "NOT_BETWEEN", name: "NOT BETWEEN" },
  ],
  NUMBER: [
    { id: "=", name: "SAME" },
    { id: ">", name: "GREATER THAN" },
    { id: "<", name: "LESS THAN" },
    { id: "BETWEEN", name: "BETWEEN" },
    { id: "NOT_BETWEEN", name: "NOT BETWEEN" },
  ],
  ID: [
    { id: "OR", name: "OR" },
    { id: "NOT_OR", name: "NOT OR" },
    { id: "AND", name: "AND" },
  ],
  BOOLEAN: [{ id: "=", name: "SAME" }],
};

const OperatorDropDown = ({
  type = "TEXT",
  valueId = null,
  onChange = () => console.log("onChange"),
}) => {

  let option = [];
  if (OPERATOR_CONFIG[type]) {
    option = OPERATOR_CONFIG[type]
  } else {
    option = OPERATOR_CONFIG.BOOLEAN
  }
  option = [
    { id: null, name: "NO SELECT" },
    ...option
  ]
  
  let value = option.find(item => item.id == valueId)

  return (
    <>
    {/* {type} */}
      <Select
        className="select2-selection"
        value={value}
        onChange={(e) => onChange(e.id)}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        options={option}
      />
    </>
    
  )
};
export default OperatorDropDown;
