import React, { useState, useEffect, useRef } from "react";
import helper from "services/helper";
import DialogCustom from "./DialogCustom";

function ModalAlert({ 
  open = false,
  title = '',
  subTitle = '',
  content = `มีบางอย่างผิดพลาด`,
  onClose = () => console.log('onClose')
}) {
  let btnText = "ปิด"

  return (
    <DialogCustom visible={open} style={{ width: '342px' }} onHide={() => onClose()} onClickOut = {() => onClose()}>
      <div className="modal-header">
        { title ? <h5 className="modal-title" id="staticBackdropLabel" dangerouslySetInnerHTML={{ __html: title }}></h5> : <></> }
      </div>
      <div className="modal-body">
        { subTitle ? <p>{ subTitle }</p> : <></> }
        { content ? <p>{ content }</p> : <></> }
      </div>
      <div className="modal-footer">
        <button 
          type="button" 
          className="btn btn-primary" 
          onClick={() => onClose()}
        >Close</button>
      </div>
    </DialogCustom>
  )
}

export default ModalAlert;
