import React, { Fragment, useEffect, useState } from "react"
import { Row, Table, Button, Col } from "reactstrap"
import { Link } from "react-router-dom"

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"


const TableContainerAPI = ({
  columns,
  data,
  tableClass,
  theadClass,
  divClassName,
  isBordered,



  sortField = "id",
  sortOrder = "asc",
  onSort = () => console.log('!onSort')
}) => {
  const [columnFilters, setColumnFilters] = useState([])
  const [globalFilter, setGlobalFilter] = useState("")

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)
    addMeta({
      itemRank,
    })
    return itemRank.passed
  }

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const { getHeaderGroups, getRowModel } = table


  return (
    <Fragment>
      <div className={divClassName ? divClassName : "table-responsive"}>
        <Table hover className={tableClass} bordered={isBordered}>
          <thead className={theadClass}>
            {getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`${
                        header.id === sortField
                          ? sortOrder === "asc"
                            ? "sorting sorting_desc"
                            : "sorting sorting_asc"
                          : header.column.columnDef.enableSorting
                            ? "sorting"
                            : ""
                      }`}
                    >
                      {header.isPlaceholder ? null : (
                        <React.Fragment>
                          <div
                            {
                              ...{
                              className: false
                                ? "cursor-pointer select-none"
                                : "",
                              // onClick: header.column.getToggleSortingHandler(),
                              onClick: () => {
                                let event = { sortField: header.id, sortOrder: 'asc'  }
                                if (sortOrder === "asc") {
                                  event.sortOrder = "desc"
                                } else {
                                  event.sortOrder = "asc"
                                }
                                console.log('header >>', header)
                                console.log('header event >>', event)
                                if (header.id ==="colcheckbox") {
                                  return
                                } else {
                                  onSort(event)
                                }
                              },
                            }
                          }
                          >
                          {
                            flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )
                          }
                          {
                            {
                              asc: "",
                              desc: "",
                            }
                            [header.column.getIsSorted()] ?? null
                          }
                          </div>
                        </React.Fragment>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          
          {/* ส่วน Table ด้านล่าง */}
          <tbody>
            {
              getRowModel().rows.map(row => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <td key={cell.id}>
                          {
                            flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          }
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    </Fragment>
  )
}

export default TableContainerAPI
