import { get, post, put } from "../Config";

export function SearchSegmentation({ params }) {
  return get({ url: `/api/g99/v1/segmentation`, params });
}
export function GetSegmentationById({ id }) {
  return get({ url: `/api/g99/v1/segmentation/${id}` });
}
export function CreateSegmentation({ params, data }) {
  return post({ url: `/api/g99/v1/segmentation/create`, params, data });
}
export function UpdateSegmentation({ params, data }) {
  return post({ url: `/api/g99/v1/segmentation/update`, params, data });
}
export function DeleteSegmentation({ params, data }) {
  return post({ url: `/api/g99/v1/segmentation/delete`, params, data });
}
export function InputformSegmentation({ params }) {
  return get({ url: `/api/g99/v1/inputform/segmentation`, params });
}
