import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

// import QRCode from "react-qr-code";

const Pagination = ({
  limit = 10,
  offset,
  rowCount,
  onBack = () => {
    console.log("onBack!");
  },
  onNext = () => {
    console.log("onNext!");
  },

  
}) => {
  const maxPage = Math.ceil(rowCount / limit);
  const currentPage = Math.floor(offset / limit) + 1;

  const onFnBack = (_currentPage = 1, _limit) => {
    console.log("onFnBack!");
    const { limit, offset } = convertPageToLimitOffset(
      _currentPage - 1,
      _limit
    );
    console.log("offset >>", offset);
    onBack(limit, offset);
  };
  const onFnNext = (_currentPage, _limit) => {
    console.log("onFnNext!");
    const { limit, offset } = convertPageToLimitOffset(
      _currentPage + 1,
      _limit
    );
    console.log("offset >>", offset);
    onNext(limit, offset);
  };
  const onFnSelectPage = (_selectPage, _limit) => {
    console.log("onFnSelectPage!");
    const { limit, offset } = convertPageToLimitOffset(
      _selectPage,
      _limit
    );
    console.log("offset >>", offset);
    onNext(limit, offset);
  };

  const pageNumbers = [];
  const pageCount = Math.ceil(rowCount / limit)
  let startI = currentPage
  if (pageCount <= 10) {
    startI = 1
  }
  for (let i = startI; i <= pageCount; i++) {
    if (pageCount <= 10) {
      pageNumbers.push(i);
    }
    else {
      if (currentPage === 1) {
        pageNumbers.push(i);
      } 
      else if (currentPage === 2) {
        pageNumbers.push(i - 1);
      } 
      else if (currentPage === 3) {
        pageNumbers.push(i - 2);
      } 
      else {
        pageNumbers.push(i - 3);
      }
      if(pageNumbers.length === 10 ) {
        break;
      }
    }
  }

  const paginationDiv="col-lg-12"
  const paginationClass="pagination pagination-rounded justify-content-center mt-3 mb-4 pb-1"

  const calShowNumber = currentPage * limit
  const showingNumber = calShowNumber <= rowCount ? calShowNumber : rowCount

  if (rowCount === 0) {
    return <></>
  }

  return (
    <>
      <React.Fragment>
          <Row className="justify-content-between align-items-center">
              <Col sm={12} md={5}>
                  <div className="text-muted dataTables_info">Showing { showingNumber } of {rowCount} entries</div>
              </Col>
              <div className={paginationDiv}>
                  <ul className={paginationClass}>
                      <li className={`page-item ${currentPage <= 1 ? "disabled" : ''}`}>
                          <a className="page-link" onClick={() => onFnBack(currentPage, limit)}>
                              <i className="mdi mdi-chevron-left"></i>
                          </a>
                      </li>
                      {(pageNumbers || []).map((item, index) => (
                          <li className={currentPage === item ? "page-item active " : "page-item"} key={index}>
                              <a className="page-link" id={item} onClick={(e) => onFnSelectPage(item, limit)}>
                                  {item}
                              </a>
                          </li>
                      ))}
                      <li className={`page-item ${currentPage >= maxPage ? "disabled" : ''}`}>
                          <a className="page-link" onClick={(e) => onFnNext(currentPage, limit)}>
                              <i className="mdi mdi-chevron-right"></i>
                          </a>
                      </li>
                  </ul>
              </div>
          </Row>
      </React.Fragment>
    </>
    
  );
};
export default Pagination;

function convertPageToLimitOffset(pageNumber, limit) {
  const offset = (pageNumber - 1) * limit;
  return { limit, offset };
}
