import React, { useEffect, useState, useMemo } from "react"
import withRouter from "components/Common/withRouter"
import TableContainerOnly from "../../components/Common/TableContainerOnly"
import helper from "services/helper"

const isDev = helper.ISDEV()

const ContentList = ({
    tableData = [],
    onOpenNew = () => console.log("onOpenNew!"),
    onOpenEdit = (index) => console.log("onOpenEdit!"),
    onOpenDelete = (index) => console.log("onOpenDelete!"),
    isView = false

}) => {
  //meta title
    document.title = "User List | Skote - React Admin & Dashboard Template"

    const handleClicks = () => {
        onOpenNew()
    }

    const columns = useMemo(
    () => [
        {
        header: "Message Type",
        accessorKey: "messageType",
        enableColumnFilter: false,
        enableSorting: false,
        width: "20%",
        cell: cell => {
            const { messageType } = cell.row.original
            switch (String(messageType)) {
            case "0":
                return <p className="text-muted mb-0">TEXT</p>

            case "1":
                return <p className="text-muted mb-0">IMAGE</p>

            case "2":
                return <p className="text-muted mb-0">VIDEO</p>

            case "3":
                return <p className="text-muted mb-0">FLEX</p>

            default:
                return <p className="text-muted mb-0">NO VALUE</p>
            }
        },
        },
        {
        header: "Content Detail",
        accessorKey: "text",
        enableColumnFilter: false,
        enableSorting: false,
        width: "80%",
        cell: cell => {
            const { messageType } = cell.row.original
            const formatText = text =>
            `${text}`.length >= 80 ? `${text}`.substring(0, 80) + "..." : text
            const { text, image, video, flexMessage } = cell.row?.original ?? {}
            return (
            <>
                {messageType == "0" && (
                <p className="text-muted mb-0">
                    {formatText(text)}
                </p>
                )}
                {messageType == "1" && (
                <p className="text-muted mb-0">
                    {formatText(image)}
                </p>
                )}
                {messageType == "2" && (
                <p className="text-muted mb-0">
                    {formatText(video)}
                </p>
                )}
                {messageType == "3" && (
                <p className="text-muted mb-0">
                    {formatText(JSON.stringify(flexMessage))}
                </p>
                )}
            </>
            )
        },
        },
        {
        header: "Action",
        accessorKey: "user_id",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => {
            let { index } = cellProps.row
            return (
                isView
                ? <>
                    <div
                        className={`btn btn-info`}
                        onClick={() => onOpenEdit(index)}
                    >
                        <i className={`bx bx-show-alt`}></i>
                    </div>
                </>
                : (
                    <div className="d-flex gap-1">
                        <div
                            className={`btn btn-warning`}
                            onClick={() => onOpenEdit(index)}
                        >
                            <i className={`bx bx-edit`}></i>
                        </div>
                        <div
                            className={`btn btn-danger`}
                            onClick={() => onOpenDelete(index)}
                        >
                            <i className={`bx bx-basket`}></i>
                        </div>
                    </div>
                )
            
            )
        },
        },
    ],
    [tableData]
    )

    return (
    <React.Fragment>
        {isDev ? JSON.stringify(tableData) : <></>}
        <div className="page-content" style={{ paddingTop: "0", paddingInline: "0" }}>
        <TableContainerOnly
            columns={columns}
            data={tableData || []}
            isGlobalFilter={true}
            isPagination={true}
            SearchPlaceholder="Search..."
            isCustomPageSize={true}
            isAddButton={isView ? false :true}
            handleUserClick={handleClicks}
            isMaxRow={true}
            maxRow={4}
            buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
            buttonName="New Content"
            tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
            theadClass="table-light"
            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
            pagination="pagination"
        />
        </div>
    </React.Fragment>
    )
}

export default withRouter(ContentList)
