import { get, post, put } from "../Config";

export function SearchBroadcast({ params }) {
  return get({ url: `/api/g99/v1/broadcast`, params });
}
export function GetBroadcastById({ id }) {
  return get({ url: `/api/g99/v1/broadcast/${id}` });
}
export function CreateBroadcast({ params, data }) {
  return post({ url: `/api/g99/v1/broadcast/create`, params, data });
}
export function UpdateBroadcast({ params, data }) {
  return post({ url: `/api/g99/v1/broadcast/update`, params, data });
}
export function DeleteBroadcast({ params, data }) {
  return post({ url: `/api/g99/v1/broadcast/delete`, params, data });
}
export function InputformBroadcast({ params }) {
  return get({ url: `/api/g99/v1/inputform/broadcast`, params });
}
