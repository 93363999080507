import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo, useCallback, useContext } from "react"
import { Row, Col, Card, CardBody, Input, Badge, Modal, CardTitle } from "reactstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { DeleteCustomer, SearchCustomer, GetCustomerByIds } from "../../services/Api/Module/Customer";
import Spinners from "components/Common/Spinner";
import Select from "react-select";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainerAPI from '../../components/Common/TableContainerAPI';
import { withTranslation } from "react-i18next";

import moment from "moment";
import Pagination from "components/Common/Pagination";
import InputWait from "components/Common/InputWait";
import ModalContext from "contexts/ModalContext";
import helper from "services/helper";
import TableContainerOnly from "components/Common/TableContainerOnly";
// import { checkScope } from "helpers/utility_helper";
const isDev = helper.ISDEV()

const STATUS_OPTION = [
    { id: null, name: "Select Verified" },
    { id: 0, name: "Not Verified" },
    { id: 1, name: "Verified" },
];

const RowStatus = ({ status }) => {
    switch (status) {
        case "0":
            return <Badge className="bg-warning"> Not Verified </Badge>;
        case "1":
            return <Badge className="bg-success"> Verified</Badge>;
        default:
            return <Badge className="bg-success"> {status} </Badge>;
    }
};
const DEF_LIMIT = 10;
const DEF_OFFSET = 0;

const CustomerList = ({
    defaultValues = [],
    onChange = () => console.log('CustomerList onChange')
}) => {
    // POPUP
    const { mAlert } = useContext(ModalContext);

    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();

    // Main DATA
    const limit = DEF_LIMIT;
    const offset = searchParams.get("offset") ?? DEF_OFFSET;
    const sortField = searchParams.get("sortField") ?? 'id';
    const sortOrder = searchParams.get("sortOrder") ?? 'asc';
    const [rowCount, setRowCount] = useState();
    const [customerList, setCustomerList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    
    const [custNo, setCustNo] = useState('');
    const [fullname, setFullname] = useState('');
    const [isVerified, setIsVerified] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    
    const [customerSelectedList, setCustomerSelectedList] = useState([]);

    const tempParams = {
        offset: offset,
        sortField: sortField,
        sortOrder: sortOrder
    }

    const onSort = (event) => {
        console.log("🚀 ~ onSort ~ event:", event)
        const { sortField, sortOrder } = event
        setSearchParams({ ...tempParams, sortField, sortOrder: sortOrder });
    };

    const onSelectionChange = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if ( findOldValue && findOldValue.length) {
            updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
            updateState = tmpOldValues
            updateState.push(selectedId)
        }

        if (mode === "all") {
            if (tmpOldValues.length >= option.length) {
            updateState = []
            } else {
            updateState = option.map(item => item.id)
            }
    
        }
        console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
        setSelectedIds(updateState)
    };

    const fetchSearchCustomer = async (payload) => {
        try {
            const response = await SearchCustomer({ params: payload })
            const resData = response.data
          // const resData = await getCustomerProfile()
            setIsLoading(false)
            if (resData.resultCode === 20000) {
                console.log('resData.resultData >>', resData.resultData)
                setCustomerList(resData.resultData)
                setRowCount(resData.rowCount)
            }
        }
        catch (err) {
            console.log('err ', err)
            setIsLoading(false)
        }
    }

    const fetchGetCustomerByIds = async (payload) => {
        try {
            const response = await GetCustomerByIds({ data: payload })
            const resData = response.data
          // const resData = await getCustomerProfile()
            setIsLoading(false)
            if (resData.resultCode === 20000) {
                console.log('fetchGetCustomerByIds resData.resultData >>', resData.resultData)
                setCustomerSelectedList(resData.resultData)
            }
        }
        catch (err) {
            console.log('err ', err)
            setIsLoading(false)
        }
    }

    const fetchAPI = () => {
        const payload = {
            custNo: custNo ? custNo : undefined,
            fullname: fullname ? fullname : undefined,
            isVerified: isVerified || isVerified == 0 ? isVerified : undefined,
            sortBy: `${sortField}|${sortOrder}`,
            offset: offset,
            limit: limit
        }
        console.log("🚀 ~ fetchAPI ~ payload.isVerified:", isVerified)
        setIsLoading(true)
        fetchSearchCustomer(payload)
    }

    useEffect(() => {
        fetchAPI()
        setSearchParams({ ...tempParams, offset: 0});
    }, [custNo, fullname, isVerified]);


    useEffect(() => {
        fetchAPI()
    }, [sortField, sortOrder, offset]);



    const onSelectAll = (_selectedIds = [], _tableList = []) => {
        const oldSelectedIds = _.cloneDeep(_selectedIds)
        const allIds = _tableList.map(item => item.id)

        if (_.isEmpty(_.difference(allIds, oldSelectedIds))) {
            // console.log('onSelectAll AAA')
            // console.log('onSelectAll AAA oldSelectedIds', oldSelectedIds)
            // console.log('onSelectAll AAA allIds', allIds)
            const newSelectIds = oldSelectedIds.filter(item => !allIds.includes(item))
            setSelectedIds(newSelectIds)

        } else {
            // console.log('onSelectAll BBB')
            // console.log('onSelectAll BBB oldSelectedIds', oldSelectedIds)
            // console.log('onSelectAll BBB allIds', allIds)
            const newSelectIds = [...new Set([ ...allIds, ...oldSelectedIds ])]
            setSelectedIds(newSelectIds)
        }
    }


    const columns = useMemo(
        () => {
            const formatDate = (txtDate = '') => {
                if (txtDate) {
                    return moment(txtDate).add('h', 7).format('DD/MM/YYYY HH:mm')
                }
                return "-"
            }
            return [
                {
                    id: "colcheckbox",
                    header: () => {
                        return (
                            <div style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => onSelectAll(selectedIds, customerList)}>
                                <Input type={`checkbox`} checked={selectedIds.length === rowCount && rowCount > 0} style={{ margin: "0px", marginBottom: "4px"}} />
                            </div>
                    )},
                    accessorKey: 'id',
                    enableColumnFilter: false,
                    enableSorting: false,
                    cell: (cellProps) => {
                        const item = { ...cellProps.row.original }
                        return (
                            <>
                                <div style={{ display: "flex", justifyContent: "center", cursor: "pointer"  }} onClick={(e) => onSelectionChange(item, _.cloneDeep(selectedIds), _.cloneDeep(customerList))}>
                                    <Input 
                                        type={`checkbox`} 
                                        checked={selectedIds.includes(item.id)} 
                                        
                                    />
                                </div>
                            </>
                        )
                    }
                },
                {
                    header: 'Customer Code',
                    accessorKey: 'custNo',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Fullname',
                    accessorKey: 'fullname',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Customer Type',
                    accessorKey: 'custType',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'isVerified',
                    accessorKey: 'isVerified',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps) => {
                        const isVerified = cellProps.row.original.isVerified
                        const tmpIsVerified = isVerified ? "1" : "0"
                        return (
                            <div className="d-flex gap-1">
                                <RowStatus status={tmpIsVerified}/>
                            </div>
                        )
                    }
                },
                {
                    header: 'Nationality',
                    accessorKey: 'nationCode',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps) => {
                        const nationCode = cellProps.row.original.nationCode
                        let textNationCode = "-"
                        if (nationCode == 1) {
                            textNationCode = "Thai"
                        }
                        else if (nationCode == 2) {
                            textNationCode = "International"
                        }
                        return (
                            <div className="d-flex gap-1">
                                {textNationCode}
                            </div>
                        )
                    }
                }
            ]
        },
        [selectedIds, customerList]
    );


    //meta title
    document.title = "Customer List";

    useEffect(()=> {
        onChange(selectedIds)
        const payload = { customerIds: selectedIds}
        fetchGetCustomerByIds(payload)
    }, [selectedIds])

    useEffect(() => {
        if (defaultValues.length && isFirstLoad) {
            setSelectedIds(defaultValues)
            setIsFirstLoad(false)
        }
    }, [defaultValues])

    return (
        <>
            <Card>
                <CardBody className="border-bottom">
                    <CardTitle className="h4">Customer Selected</CardTitle>
                    <TableContainerOnly
                        columns={columns}
                        data={customerSelectedList || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="Search..."
                        isCustomPageSize={true}
                        isMaxRow={true}
                        maxRow={4}
                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                        buttonName="New Content"
                        tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                    />
                </CardBody>
            </Card>
            <Card>
                {
                    isDev && (
                        <div>IN: {JSON.stringify(selectedIds)}</div>
                    )
                }
                <CardBody className="border-bottom">
                    <div className="d-flex align-items-center mb-3">
                        <h5 className="mb-0 card-title">Customer Lists</h5>
                    </div>
                                    <div className="d-flex ">
                        <div className="d-flex align-items-center flex-grow-1 gap-2">
                            <Row style={{width: "100%"}}>
                                <InputWait
                                    value={custNo ?? ""}
                                    onChange={value => setCustNo(String(value))}
                                    className="form-control search-box me-2 mb-2 d-inline-block"
                                    placeholder={"Customer Code"}
                                />
                                <InputWait
                                    colSpan={3}
                                    value={fullname ?? ""}
                                    onChange={value => setFullname(String(value))}
                                    className="form-control search-box me-2 mb-2 d-inline-block"
                                    placeholder={"Search Full Name"}
                                />
                                <Col sm={3}>
                                    <Select
                                        className="select2-selection"
                                        placeholder="Select Status"
                                        value={helper.FNFORM.getObjectValue(STATUS_OPTION, isVerified)}
                                        onChange={(e) => setIsVerified(e.id)}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        options={STATUS_OPTION}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="d-flex " style={{ minHeight: 36.5 }}>
                        <div className="d-flex align-items-center flex-grow-1"></div>
                        {
                            selectedIds.length > 0 && (
                                <div className="flex-shrink-0 d-flex gap-2">
                                    <a className="btn btn-info" onClick={() => setSelectedIds([])}> Clear Selected All</a>
                                </div>
                            )
                        }
                    </div>
                </CardBody>
                <CardBody>
                    
                    <TableContainerAPI
                        SearchPlaceholder="Search"
                        columns={columns}
                        data={customerList || []}
                        onSort={onSort}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        isAddButton={true}
                        addRowLink={`/useradd`}
                        isExportButton={true}
                        exportLink={`/userexport`}
                        isGlobalFilter={true}
                        tableClass="table-bordered table-nowrap dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                    />

                    <Pagination
                        limit={limit}
                        offset={offset}
                        rowCount={rowCount}
                        onBack={(limit, offset) => {
                            setSearchParams({ ...tempParams, offset });
                        }}
                        onNext={(limit, offset) => {
                            setSearchParams({ ...tempParams, offset });
                        }}
                    />
                </CardBody>
            </Card>
        </>
        
    );
}
export default CustomerList