import React, { useEffect, useContext, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Container,
  CardTitle,
  Label,
  Card,
  Input,
  InputGroup
} from "reactstrap";
import _ from "lodash";
import Select from "react-select";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import helper from "services/helper";

const OperatorInput = ({
  labelInput = "FORM",
  type = "TEXT",
  operator = "",
  value = [],
  option = [],
  disabled = false,
  onChange = () => console.log("onChange"),
}) => {


  console.log("🚀 ~ OperatorInput type:", type)
  console.log("🚀 ~ OperatorInput operator:", operator)
  console.log("🚀 ~ OperatorInput value:", value)


  if (type === "DATE") {
    if (operator === "BETWEEN" || operator === "NOT_BETWEEN" ) {
      const  [ start = null, end = null ] = value

      const onChangeDate = (event, from = '', oldValue = []) => {
        const [ tmpStart = null, tmpEnd = null ] = oldValue
        const newValue = moment(event).format('YYYY-MM-DD')
        console.log("🚀 ~ onChangeDate ~ event:", event)
        console.log("🚀 ~ onChangeDate ~ event newValue:", newValue)

        let updateState = []

        console.log("🚀 ~ onChangeDate updateState ~ from:", from)
        if (from === 'start') {
          updateState = [ newValue, tmpEnd ]
          console.log("🚀 ~ onChangeDate ~ updateState AAA:", updateState)
        }
        else if (from === 'end') {
          updateState = [ tmpStart, newValue ]
          console.log("🚀 ~ onChangeDate ~ updateState BBB:", updateState)
        }

        onChange(updateState)

      }

      const convertValue = (_value = null) => {
        if (_value) {
          return moment(_value).toDate()
        }
        return null
      }

      return (
        <>
          <div className="col-md-3">
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="Start Date"
                value={start}
                onChange={(e) => onChangeDate(e[0], 'start', value)}
                disabled={disabled}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d",
                  maxDate: convertValue(end)
                }}

              />
            </InputGroup>
          </div>
          <div className="col-md-3">
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="End Date"
                value={end}
                onChange={(e) => onChangeDate(e[0], 'end', value)}
                disabled={disabled}
                options={{
                  altInput: true,
                  altFormat: "Y-m-d",
                  dateFormat: "Y-m-d",
                  minDate: convertValue(start)
                }}
              />
            </InputGroup>
          </div>
        </>
      );
    }
  }


  if (type === "ID") {
    if (operator === "OR" || operator === "NOT_OR" || operator === "AND") {
      const values = value
      const onChangeID = (event) => {
        const newValues = _.cloneDeep(event)
        let updateState = newValues.map(item => item.id)
        onChange(updateState)
      }

      const convertIDValue = (_values = [], _option = []) => {
        if (_values && _values.length) {
          return _option.filter(item => _values.includes(item.id))
        }
        return []
      }

      return (
        <div className="col-md-6">
          <Select
            className="select2-selection"
            isMulti={true}
            value={convertIDValue(values, option)}
            onChange={(e) => onChangeID(e)}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
            options={option}
            isDisabled={disabled}
          />
        </div>
      )
    }
  }

  if (type === "BOOLEAN") {
    if (operator === "=" ) {
      const  [ _value ] = value
      // const _value = "false"
      const onChangeBoolean = (value) => {
      console.log("BOOLEAN X onChangeBoolean ~ event:", value)
      console.log("BOOLEAN X onChangeBoolean ~ event checked: ", value)
      let updateState = [ value ]
      onChange(updateState)

      }

      const convertBooleanValue = (_value = null) => {
        if (_value != null) {
          return `${_value}`
        }
        return null
      }
      // console.log('BOOLEAN X _value >>', _value)
      return (
        <div className="col-md-6" style={{ display: 'flex', alignItems: 'center', pointerEvents: disabled ? 'none' : 'auto' }}>
          <div className="form-check form-check-inline" onClick={() => onChangeBoolean("true")}>
            <Input
              type="radio"
              id="customRadioInline1"
              className="form-check-input"
              name="customRadioInline1 bool"
              value="true"
              // onChange={(e) => onChangeBoolean(e)}
              checked={convertBooleanValue(_value) === "true"}
              disabled={disabled}
            />
            <label className="form-check-label" htmlFor="customRadioInline1">True</label>
          </div>
          <div className="form-check form-check-inline" onClick={() => onChangeBoolean("false")}>
            <Input
              type="radio"
              className="form-check-input"
              id="customRadioInline2"
              name="customRadioInline1 bool"
              value="false"
              // onChange={(e) => onChangeBoolean(e)}
              // checked={_value === "false"}
              checked={convertBooleanValue(_value) === "false"}
              disabled={disabled}
            />
            <label className="form-check-label" htmlFor="customRadioInline2">False</label>
          </div>
        </div>
      )
    }
  }

  if (type === "ENUM") {
    if (operator === "OR" || operator === "NOT_OR") {
      const values = value
      const onChangeEnum = (selectedValue = {}, oldValues = [], option = [], mode = "") => {
        console.log("🚀 ~ onChangeEnum ~ selectedValue:", selectedValue)
        const selectedId = selectedValue?.id
        const tmpOldValues = _.cloneDeep(oldValues)
        const findOldValue = tmpOldValues.filter(id => id === selectedId)

        let updateState = []
        if ( findOldValue && findOldValue.length) {
          updateState = tmpOldValues.filter(id => id != selectedId)
        } else {
          updateState = tmpOldValues
          updateState.push(selectedId)
        }
        // let updateState = newValues.map(item => item.id)
        

        if (mode === "all") {
          if (tmpOldValues.length >= option.length) {
            updateState = []
          } else {
            updateState = option.map(item => item.id)
          }
  
        }
        console.log("🚀 ~ onChangeEnum ~ updateState:", updateState)
        onChange(updateState)
      }
      const convertEnumValue = (_values = [], currentId = null) => {
        const arrChecked = _values.filter(item => item ==  currentId)
        console.log("🚀 ~ convertEnumValue ~ currentId:", currentId)
        console.log("🚀 ~ convertEnumValue ~ _values:", _values)
        console.log("🚀 ~ convertEnumValue ~ arrChecked:", arrChecked)
        if (arrChecked && arrChecked.length) {
          return true
        }
        return false
      }

      return (
        <div className="col-md-6" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div 
            style={{ display: 'flex', alignItems: 'center', gap: '8px', minWidth: '65px', pointerEvents: disabled ? 'none' : 'auto'  }}
            onClick={(e) => onChangeEnum({}, value, option, "all")}
          >
            <input
              type="checkbox"
              className="form-check-input"
              style={{ margin: 0 }}
              name="inputCheck"
              
              checked={values.length === option.length}
              disabled={disabled}
            />
            <label
              className="form-check-label"
            >
              All
            </label>
          </div>
          {
            option.map((item) => {
                return (
                  <div 
                    key={item.id} 
                    style={{ display: 'flex', alignItems: 'center', gap: '8px', minWidth: '65px', pointerEvents: disabled ? 'none' : 'auto' }}
                    onClick={(e) => onChangeEnum(item, values, option)}
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      style={{ margin: 0 }}
                      name="inputCheck"
                      checked={convertEnumValue(values, item.id) }
                      disabled={disabled}
                    />
                    <label
                      className="form-check-label"
                    >
                      {item.name}
                    </label>
                  </div>
                );
              })
            }
        </div>
        
      )
    }
  }

  if (type === "NUMBER") {
    if (operator === "=" || operator === "<" || operator === ">" ) {
      const  [ _value = 0 ] = value
      // // const _value = "false"
      const onChangeNumber = (event) => {
      console.log("🚀 ~ onChangeNumber ~ event:", event)
      console.log("🚀 ~ onChangeNumber ~ event value:", event.target.value)
      let updateState = [ event.target.value ]
      onChange(updateState)

      }

      return (
        <div className="col-md-3">
          <Input
            type="text"
            maxLength="255"
            name="number"
            value={_value}
            onInput={(e)=> helper.FNFORM.handleNumberOnly(e)}
            onChange={(e) => onChangeNumber(e)}
            disabled={disabled}
          />
        </div>
      )
    }

    else if (operator === "BETWEEN" || operator === "NOT_BETWEEN") {
      const  [ start = 0, end = 0] = value

      const onChangeNumberTwin = (event, from = '', oldValue = []) => {
        const [ tmpStart = null, tmpEnd = null ] = oldValue

        console.log("🚀 ~ onChangeNumber ~ event:", event)
        console.log("🚀 ~ onChangeNumber ~ event value:", event.target.value)

        const newValue = event.target.value
        let updateState = []
        if (from === 'start') {
          updateState = [ newValue, tmpEnd ]
        }
        else if (from === 'end') {
          updateState = [ tmpStart, newValue ]
        }
        onChange(updateState)

      }

      return (
        <>
          <div className="col-md-3">
            <Input
              type="text"
              maxLength="255"
              name="numberStart"
              value={start}
              onInput={(e)=> helper.FNFORM.handleNumberOnly(e)}
              onChange={(e) => onChangeNumberTwin(e, 'start', value)}
              disabled={disabled}
            />
          </div>
          <div className="col-md-3">
            <Input
              type="text"
              maxLength="255"
              name="numberEnd"
              value={end}
              onInput={(e)=> helper.FNFORM.handleNumberOnly(e)}
              onChange={(e) => onChangeNumberTwin(e, 'end', value)}
              disabled={disabled}
            />
          </div>
        </>
        
      )
    }
  }

  // if (type === "TEXT") {
  //   if (operator === "OR" || operator === "NOT_OR") {
  //     const values = value
  //     const onChangeID = (event) => {
  //       const newValues = _.cloneDeep(event)
  //       let updateState = newValues.map(item => item.id)
  //       onChange(updateState)
  //     }

  //     const convertIDValue = (_values = [], _option = []) => {
  //       if (_values && _values.length) {
  //         return _option.filter(item => _values.includes(item.id))
  //       }
  //       return []
  //     }

  //     return (
  //       <div className="col-md-6">
  //         <Select
  //           className="select2-selection"
  //           isMulti={true}
  //           value={convertIDValue(values, option)}
  //           onChange={(e) => onChangeID(e)}
  //           getOptionValue={(option) => option.id}
  //           getOptionLabel={(option) => option.name}
  //           options={option}
  //         />
  //       </div>
  //     )
  //   }
  // }




  return <></>

};
export default OperatorInput;
