import React, { useEffect, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Col, Row } from "reactstrap"

// import QRCode from "react-qr-code";

const InputWait = ({
  value: initialValue,
  onChange,
  debounce = 500,
  colSpan = 4,
  ...props
}) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [debounce, onChange, value])

  return (
    <React.Fragment>
      <Col sm={colSpan}>
        <input style={{ marginBottom: '0px !important' }} {...props} value={value} onChange={e => setValue(e.target.value)}
        />
      </Col>
    </React.Fragment>
  )
}
export default InputWait
