import React from "react";
import { Navigate } from "react-router-dom";

import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import SegmentationList from "pages/Segmentation/SegmentationList";
import SegmentationTemplate from "pages/Segmentation/SegmentationTemplate";
import BroadcastList from "pages/Broadcast/BroadcastList";
import BroadcastTemplate from "pages/Broadcast/BroadcastTemplate";
import CustomerGroupList from "pages/CustomerGroup/CustomerGroupList";
import CustomerGroupTemplate from "pages/CustomerGroup/CustomerGroupTemplate";
import CustomerList from "pages/CustomerGroup/CustomerList";

const authProtectedRoutes = [

  { path: "/", component: <Navigate to={{ pathname: "/segmentation" }} />},
  { path: "/segmentation", component: <SegmentationList/> },
  { path: "/segmentation-add", component: <SegmentationTemplate pageView="create"/> },
  { path: "/segmentation-edit/:id", component: <SegmentationTemplate pageView="update"/> },
  { path: "/segmentation-view/:id", component: <SegmentationTemplate pageView="view"/> },

  { path: "/broadcast", component: <BroadcastList/> },
  { path: "/broadcast-add", component: <BroadcastTemplate pageView="create"/> },
  { path: "/broadcast-edit/:id", component: <BroadcastTemplate pageView="update"/> },
  { path: "/broadcast-view/:id", component: <BroadcastTemplate pageView="view"/> },

  { path: "/customer-group", component: <CustomerGroupList/> },
  { path: "/customer", component: <CustomerList/> },
  { path: "/customer-group-add", component: <CustomerGroupTemplate pageView="create"/> },
  { path: "/customer-group-edit/:id", component: <CustomerGroupTemplate pageView="update"/> },
];

export { authProtectedRoutes };
