const config = () => {
  return {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    LIFF_ID: process.env.REACT_APP_LIFF_ID,
    APP_ENV: process.env.REACT_APP_ENV,
  };
};

export default {
  config,
};
