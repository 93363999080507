import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  CardBody,
  Container,
  CardTitle,
  Label,
  Card,
  Input,
  FormFeedback,
  InputGroup
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";


// Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { InputformCustomerGroup, CreateCustomerGroup, GetCustomerGroupById, UpdateCustomerGroup } from "../../services/Api/Module/CustomerGroup";
import { DDSegmentation } from "../../services/Api/Module/Dropdown";
import { Link } from "react-router-dom";
import OperatorDropDown from "./OperatorDropDown";
import OperatorInput from "./OperatorInput";
import helper from "services/helper";
import moment from "moment";
import ModalContext from "contexts/ModalContext";
import CustomerList from "./CustomerList";

const isDev = helper.ISDEV()
const TIME_OPTION = helper.TIME.generateTimes().map(item => ({ id:item, name: item}));
const DEF_CONTENTS = [
  {
    customerGroupId: "",
    messageType: "0",
    text: "Hello Text",
  },
  {
    customerGroupId: "",
    messageType: "1",
    label: "นี้คือ Image",
    image:
      "https://p16-va.lemon8cdn.com/tos-alisg-i-0000/01ddd41d163a43da98e9066861b7a188~tplv-tej9nj120t-origin.webp",
    embedLink: "https://www.google.co.th",
  },
  {
    customerGroupId: "",
    messageType: "2",
    label: "นี้คือ Video",
    video:
      "https://v16-webapp-prime.tiktok.com/video/tos/alisg/tos-alisg-pve-0037c001/oY6gBRfDQIemR9mERGwqcFURVBpAEEpyFQDcEg/?a=1988&bti=ODszNWYuMDE6&ch=0&cr=3&dr=0&lr=unwatermarked&cd=0%7C0%7C0%7C&cv=1&br=4138&bt=2069&cs=0&ds=6&ft=-Csk_mXLPD12N88NRT-Uxt~2KY6e3wv25xcAp&mime_type=video_mp4&qs=0&rc=ZDhpPGY1ZGc1OWU1O2lnNUBpM246cng5cnFrcjMzODczNEBhXi00XjViNTExMC8yMmAuYSNpbi81MmQ0aF5gLS1kMS1zcw%3D%3D&btag=e00088000&expire=1721119711&l=20240716024815F5684C6FA251034591DE&ply_type=2&policy=2&signature=b462ec18b72d0e4b77a9df6a4af1fdec&tk=tt_chain_token",
    coverVideo:
      "https://p16-sign-sg.tiktokcdn.com/obj/tos-alisg-p-0037/o0AMntn2cqAQBI5E64PBZpFKEiYNViBEDVal1?lk3s=81f88b70&nonce=95050&refresh_token=1b321e49120d753e706720a603d5d7bf&x-expires=1721268000&x-signature=6QkU9RzvhLoW86CwtXuK%2BgWiXTw%3D&shp=81f88b70&shcp=-",
  },
  {
    customerGroupId: "",
    messageType: "3",
    label: "นี้คือ Flex Message",
    flexMessage: { flex: "1234" },
  },
]

const CustomerGroupTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();

  const qParams = useParams()
  const qCustomerGroupId = qParams.id

  // dropdown API
  const [ddSegmentation, setDDSegmentation] = useState([]);

  // form
  const [customerGroupName, setCustomerGroupName] = useState("");

  // form List
  const [details, setDetail] = useState([]);

  // INPUT FORM
  const [isSubmit, setIsSubmit] = useState(false)
  const [inputformSegment, setInputformSegment] = useState([])
  const [inputformValue, setInputformValue] = useState({})

  // Popup State
  const [isOpenP0, setIsOpenP0] = useState(false)
  const [modeP0, setModeP0] = useState('view')
  const [indexP0, setIndexP0] = useState(0)



  const fetchDDSegmentation = async () => {
    try {
      const response = await DDSegmentation()
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20000) {
        console.log('resData.resultData >>', resData.resultData)
        setDDSegmentation(resData.resultData)
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
      })
    }
  }

  const fetchCreateCustomerGroup = async (payload = {}) => {
    try {
      const response = await CreateCustomerGroup({ data: payload })
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20201) {
        mAlert.info({
          title: "Alert",
          subTitle: "Create Data Success",
          content: "",
          onClose: () => {
            const newId = resData.resultData.id
            // navigate("/customer-group-edit/" + newId)
            navigate("/customer-group")
          },
        })
        
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
      })
    }
  }
  const fetchUpdateCustomerGroup = async (payload = {}) => {
    try {
      const response = await UpdateCustomerGroup({ data: payload })
      const resData = response.data
      // const resData = await getCustomerProfile()
      if (resData.resultCode === 20202) {
        mAlert.info({
          title: "Alert",
          subTitle: "Update Data Success",
          content: "",
          onClose: () => {
            navigate("/customer-group")
          },
        })
        // const newId = resData.resultData.id
        // navigate("/customer-groupation-edit/" + newId)
      }
    }
    catch (err) {
      console.log('err ', err)
      mAlert.info({
          title: "Alert",
          subTitle: "Something went wrong",
          content: "",
          onClose: () => {
          //  alert("onClose")
          },
      })
    }
  }

  const fetchGetCustomerGroupById = async (payload) => {
    try {
        const response = await GetCustomerGroupById({ id: payload.customerGroupationId })
        const resData = response.data
      // const resData = await getCustomerProfile()
        if (resData.resultCode === 20000) {
            console.log('resData.resultData fetchGetCustomerGroupById >>', resData.resultData)
            preFilInput(resData.resultData)
            // setCustomerGroupList(resData.resultData)
            // setRowCount(resData.rowCount)
        }
    }
    catch (err) {
        // console.log('err ', err)
    }
  }


  const checkValidate = (payload = {}) => {
    console.log("🚀 ~ checkValidate ~ payload:", payload)
    


    let isPass = true

    // เช็คด้านนอก ฟิวปกติ
    const requireField = ['customerGroupName']
    for (let rk of requireField) {
      console.log("🚀 ~ checkValidate ~ payload["+ rk +"]:", payload[rk])
      if (!payload[rk]) {
        isPass = false;
        break
      }
    }

    // เช็คด้านนอก ฟิวArray
    const requireFieldArray = ['details']
    for (let rak of requireFieldArray) {
      console.log("🚀 ~ checkValidate Array ~ payload["+ rak +"]:", payload[rak])
      if (Array.isArray(payload[rak]) && payload[rak].length === 0) {
        isPass = false;

        // เขียนเพิ่ม
        if (rak === "details") {
          mAlert.info({
            title: "Alert",
            subTitle: "Please select at least 1 Detail.",
            content: "",
            onClose: () => {
              //  alert("onClose")
            },
          })
        }
        break
      }
    }

    // เช็คด้านใน Filter ต่อ
    if (isPass) {
      // ค่อยมาเขียนต่อ
    }

    return isPass
  }

  const onSave = () => {
    const payload = {
      id: qCustomerGroupId ? qCustomerGroupId : undefined,
      customerGroupName: customerGroupName,
      details: details.map(item => ({ customer: item }))
    };
    console.log("🚀 ~ onSave ~ payload:", payload);
    setIsSubmit(true)

    if (checkValidate(payload)) {
      if (pageView === "update") {
        fetchUpdateCustomerGroup(payload)
      } else {
        fetchCreateCustomerGroup(payload)
      }
    }
  };


  const preFilInput = (resData = {}) => {
    setCustomerGroupName(resData?.name)
    let detailsData = resData?.details ?? []
    detailsData = detailsData.map(item => item.customer)
    setDetail(detailsData)
  }

  useEffect(() => {
    fetchDDSegmentation()
  }, [])

  // กรณี Edit
  useEffect(() => {
    
    if (pageView === "update") {
      const payload = { customerGroupationId : qCustomerGroupId }
      fetchGetCustomerGroupById(payload)
    }
  }, [])

  //meta title
  document.title = `CustomerGroup ${_.capitalize(pageView)}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Create" breadcrumbItem="CustomerGroup" backBTN={'/customer-group'}/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">CustomerGroup {_.capitalize(pageView)}</CardTitle>
                  <p className="card-title-desc">
                    Fill all information below
                  </p>
                  {/* <p className="card-title-desc">
                    Here are examples of <code>.form-control</code> applied to
                    each textual HTML5 <code>&lt;input&gt;</code>{" "}
                    <code>type</code>.
                  </p> */}
                  <div className="mb-3">
                    <Label>Customer Group Name</Label>
                    <Input
                      type="text"
                      maxLength="255"
                      name="customerGroupName"
                      value={customerGroupName}
                      onChange={(e) => setCustomerGroupName(e.target.value)}
                      invalid={isSubmit && !customerGroupName}
                    />
                    {
                      (isSubmit && !customerGroupName) && (
                        <FormFeedback type="invalid">This field is required</FormFeedback>
                      )
                    }
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {/* {JSON.stringify(inputformValue)} */}
                  <CardTitle className="h4">Detail</CardTitle>
                  <p className="card-title-desc">
                    (You must select at least 1 detail)
                  </p>
                  {
                    isDev && (
                      <div>OUT: {JSON.stringify(details)}</div>
                    )
                  }
                  <CustomerList 
                    defaultValues={details}
                    onChange={(_selectedIds) => setDetail(_selectedIds)}
                  />
                  <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                    <button className="btn btn-primary w-md" onClick={() => onSave()}>SAVE</button>
                    <button className="btn btn-secondary w-md" onClick={()=> navigate('/customer-group')}>CANCEL</button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomerGroupTemplate
